/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";

*,
*:after,
*:before {
	box-sizing: inherit;
}
html {
    box-sizing: border-box;
}
body {
    color: #333;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    overflow-x: hidden;
    font-weight: 500;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Montserrat', sans-serif;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; }
h5 { font-size: 16px; }
h6 { font-size: 14px; }

.fl {float: left;}
.fr {float: right;}

a {@include transition(all .16s ease-in-out);}



/* structure
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper, .container {
    @include clearfix();
    width: 1170px; margin: 0 auto; position: relative;
}

button {padding: 0; border:0; cursor: pointer;}

.btn {
    display: inline-block; height: 36px; line-height: 36px; color: $c_fff; font-size: 13px; font-weight: bold;
    padding: 0 24px; background: $c_9c9; @include borderRadius(30px);
    @include boxSizing(border-box);
    &:hover {
        background:$c_a6a; color: $c_fff;
    }
    &.v2 {
        background: none; border:2px solid $c_fff; padding: 0 50px; height: 50px; line-height: 45px;
        &:hover {background:$c_a6a; border-color: $c_a6a;}
    }
    &.btn-more {
        background: none; color: $c_green_5; padding: 0;
        &:hover {color:$c_green_2;}
    }
    &.medium {padding: 0 65px; font-size: 16px;}
    &.medium-2 {padding: 0 45px; font-size: 16px; height: 42px; line-height: 42px;}
    &.outline {
        background: none; border:2px solid $c_9c9; padding: 0 38px; line-height: 32px;
        color: $c_9c9;
        &:hover {background:$c_a6a; border-color: $c_a6a; color: $c_fff;}
    }
    &.yellow {
        padding: 0 40px; background:$c_yellow;
        &:hover {
            background:$c_yellow_5;
        }
    }
    &.green {
        background: $c_green; color: $c_fff;
        &:hover {
            background:$c_green_5;
        }
    }
    &.green-outline {
        background: none; border:2px solid $c_green; color: $c_green; line-height: 46px; height: 50px;
        padding: 0 45px;
        &:hover {
            background:$c_green; color: $c_fff;
        }
    }
    &.white {color: $c_fff;}
}
.only-mobile{
  display: none;
}


/* header
----------------------------------------------------------------------------------------------*/
header {
    position: fixed; width: 100%; height: 140px; background: $c_fff; top: 0; left: 0; right: 0; z-index: 898989;
    border-bottom: 1px solid $c_e9e;
    @include transition(all .3s ease-in-out);
    .logo {
        padding: 28px 0 0 0; @include transition(all .5s ease-in-out);
        a {display: block; line-height: 0;}
    }
    .container {position: static;}
    .menu-top {
        margin-bottom: 45px; text-align: right;
        a {
            display: inline-block; font-size: 13px; font-weight: 600; color: $c_fff; vertical-align: top;
            padding: 13px 25px; background:$c_yellow; margin: 0 -3px 0 0;
            @include borderRadius(0 0 15px 15px);
            &.login {
                background: none; color: $c_749; position: relative;
                &:after {
                    position:absolute; width: 14px; height: 20px; content: ""; left: 0; top: 50%;
                    background: url('../images/material/ico-login.png') no-repeat 0 0;
                    @include transform(translateY(-50%));
                }
            }
            &:last-child {margin-right: 0;}
        }
        .lang {
            display: inline-block; position: relative; margin-right: 20px;
            .act {
                background: none; color: #74927b; padding-right: 20px; position: relative; text-align: left; padding: 8px 20px 0 20px;
                &:after {
                    content:''; width: 8px; height: 5px; background: url('../images/material/arr-green.png') no-repeat; background-size: 8px;
                    display: block; position: absolute; right: 5px; top: 6px; bottom: 0; margin: auto;
                }

            }
            ul {
                left: auto;background: #fff; position: absolute; top: 100%; right: 0; @include boxShadow(0 7px 20px -3px rgba(0,0,0,0.15)); @include borderRadius(0 0 3px 3px); display: none; padding-bottom: 5px; z-index: 999;
                li {
                    a {background: none; color: #74927b; text-align: left;height: 30px; line-height: 30px; display: block; padding: 0 18px !important;}
                }
            }
        }
    }
    .burger-menu {
        display: none; float: right; position: relative; right: 0; cursor: pointer;
        width: 34px; height: 29px; z-index: 858585; margin: 26px 0 0 23px;
        span {
            position: absolute; width: 34px; height: 5px; background: $c_green_10; display: block; text-indent: -999999px;
            right: 0;
            @include borderRadius(3px);
            @include transform(rotate(0deg));
            @include transition( .25s ease-in-out);

            &:nth-child(1) {
                top: 0;
                @include transformorigin(left center);
            }
            &:nth-child(2) {
                top: 12px; width: 70%;
                @include transformorigin(left center);
            }
            &:nth-child(3) {
                top: 24px;
                @include transformorigin(left center);
            }
        }

        &.expand {
            span {
                position: absolute; display: block;
                right: 0;
                @include borderRadius(0px);
                &:nth-child(1) {top: 0px; @include transform(rotate(45deg));}
                &:nth-child(2) {top: 0px; opacity: 0;}
                &:nth-child(3) {top: 24px; @include transform(rotate(-45deg));}
            }
        }
    }
    nav {
        ul {
            li {
                display: inline-block; vertical-align: top;
                a {
                    display: block; font-size: 16px; font-weight: 500; color: $c_333;
                    padding: 22px 25px; position: relative;
                    @include boxSizing(border-box);
                    &:after {
                        position:absolute; width:0; height: 6px; background: $c_004; content: "";
                        left: 50%; bottom: 0; @include transform(translate(-50%,-50%));
                        @include transition(all .15s ease-in-out);
                    }
                    &.active {
                        color: $c_004;
                        &:after {width: 46px;}
                    }
                }
                &:hover {
                    a {
                        color: $c_004;
                        &:after {width: 46px;}
                    }
                }
                .subdropdown {
                    position: absolute; width: 100%; min-height: 508px;
                    left: 0; top: 101%; display: none;
                    &:before {
                        position:absolute; width: 100%; height: 100%; content: "";
                        background: $c_7fc; top: 0; left: 0; opacity: 0.95;
                    }
                    .row {
                        @include clearfix();
                        position: relative; width: 805px; margin:auto; padding: 70px 0;
                        .col {
                            float:left; @include boxSizing(border-box);
                            &:nth-child(2n+1) {width: 60.5%; padding-right: 60px;}
                            &:nth-child(2n+2) {width: 39.5%;}
                            .img {
                                line-height: 0; margin-bottom: 30px;
                                @include boxShadow(0px 0px 15px 0px rgba(0, 0, 0, 0.4));
                                img {width: 100%;}
                            }
                            p {color: $c_fff; font-size: 14px;}
                            .nav-sub {
                                ul {
                                    li {
                                        padding: 0 0 15px 0; display: block; float: none;
                                        a {
                                            padding: 0; color: $c_fff; font-size: 18px; font-weight: 800;
                                            &:after {display:none;}
                                        }
                                        ul {
                                            padding: 15px 0 15px 10px;
                                            li {
                                                padding: 0;
                                                a {
                                                    font-size: 14px; padding: 5px 0 5px 15px;
                                                    position: relative; font-weight: 500;
                                                    &:before {
                                                        position:absolute; width: 5px; height: 2px;
                                                        background: $c_fff; content: ""; left: 0; top: 50%;
                                                        @include transform(translateY(-50%));
                                                    }
                                                }
                                            }
                                        }
                                        &.sn-dropdown {
                                            a.parent {
                                                &.expanded {
                                                    color: $c_004;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.float {
        background: $c_fff; height: 90px;
        .logo {
            width: 160px; padding-top: 10px;
        }
        .menu-top {
            margin-bottom: 7px;
            .lang {
                .act {
                    padding-top: 4px;
                    &:after {top: 3px;}
                }
            }
            a {
                padding: 8px 20px; font-size: 12px;
                &:last-child {padding:8px 20px;}
            }
        }
        nav {
            ul {
                li {
                    a {font-size: 15px;}
                }
            }
        }
    }
}

.nav-mobile {
    position: fixed; width: 100%; height: 100%; z-index: 89888; padding: 84px 0 50px 0;
    background:$c_7fc; display: none; opacity: 0; overflow: auto;
    @include boxSizing(border-box);
    .navmob {
        padding: 0 50px 0 50px; position: relative; width: 100%; height: auto;
        left: 0; top: 0;
        @include boxSizing(border-box);
        ul {
            padding: 50px 0 0 0; overflow: auto; position: relative;
            li {
                text-align: center;
                a {
                    display: block; padding: 35px 0; font-size: 38px; color: $c_fff;
                }
                ul {
                    padding: 0; height: 70%;
                }
            }
        }
        .subdrop, .subdrop-2 {
            position: fixed; width: 100%; height: 78%; z-index: 8888; padding: 0 37px 0 37px;
            background:$c_7fc; top: 84px; left: 100%; overflow: auto;
            @include boxSizing(border-box);
            .title {
                font-size: 38px; font-weight: bold; color: $c_green_10; margin-bottom: 50px;
                a {
                    padding: 0; color: $c_green_10;
                }
            }
            .back, .back-2 {
                margin: 20px 0;
                a {
                    width: 42px; height: 42px; display: block; text-indent: -999999px;
                    background: url('../images/material/btn-arrow-prev.png') no-repeat center center;
                }
            }
            li {

                a {
                    padding: 21px 0; font-size: 25px; font-weight: 300;
                }
            }
        }
    }
    .social-mob {
        position: relative; width: 100%; left: 0; padding: 15px 0 0 0;
        ul {
            text-align: center;
            li {
                display: inline-block; padding: 0 25px;
                a {
                    display: block; height: 41px; text-indent: -999999px;
                    &.sm-fb {width: 41px; background:url('../images/material/sm-fb.png') no-repeat center center;}
                    &.sm-link {width: 41px; background:url('../images/material/sm-link.png') no-repeat center center;}
                    &.sm-ig {width: 42px; background:url('../images/material/sm-ig.png') no-repeat center center;}
                    &.sm-yt {width: 48px; background:url('../images/material/sm-yt.png') no-repeat center center;}
                }
            }
        }
    }
    .info-mob {
        display: none;
        position: relative;
        /*bottom: 40px; left: 50%; */
        text-align: center; width: 180px;
        font-size: 13px; font-weight: 600; color: $c_fff; z-index: 15;
        padding: 13px 25px; background:$c_yellow; margin: 0 0px 0 0;
        height: 40px; line-height: 40px; padding: 0 20px;
        @include borderRadius(30px); @include boxSizing(border-box);
        /*@include transform(translate(-50%,-50%)); */
    }
}


.middle {
    padding-top: 140px; position: relative; overflow: hidden;
    &.full {padding: 0;}
    &.career {min-height: 990px;}
}



/* middle homepage
----------------------------------------------------------------------------------------------*/
.slick-initialized .slick-slide {position: relative;}
.slick-arrow {
    position: absolute; width: 90px; height: 60px; background: rgba(0,0,0, 0.5); top: 50%; padding: 0;
    border:0; cursor: pointer; color: $c_fff; z-index: 5; text-indent: -999999px;
    @include transform(translateY(-50%));
    &:after {
        position:absolute; width: 42px; height: 42px; content: ""; top: 50%;
        @include transform(translateY(-50%));
    }
    &.slick-prev {
        left: 0; @include borderRadius(0 30px 30px 0);
        &:after {
            right:10px;
            background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;
        }
    }
    &.slick-next {
        right: 0; @include borderRadius(30px 0 0 30px);
        &:after {
            left:10px;
            background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;
        }
    }
    &:hover {
        &.slick-prev {
            &:after {background:url('../images/material/btn-arrow-prev-yellow.png') no-repeat 0 0;}
            &.slick-disabled {
                &:after {background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;}
            }
        }
        &.slick-next {
            &:after {background:url('../images/material/btn-arrow-next-yellow.png') no-repeat 0 0;}
            &.slick-disabled {
                &:after {background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;}
            }
        }
    }
    &.slick-disabled {cursor: default; opacity: 0.5;}
}
.slick-dots {
    position: absolute; width: 100%; bottom: 30px; padding: 0 0 0 0 !important;
    text-align: center;
    @include boxSizing(border-box);
    li {
        display: inline-block; margin: 0 5px; padding: 0 !important;
        &:before {display:none;}
        button {
            cursor: pointer; padding: 0; border:0; width: 15px; height: 15px; background: $c_fff;
            text-indent: -999999px; opacity: 0.7;
            @include borderRadius(50%);
        }
        &.slick-active {
            button {background: $c_yellow; opacity: 1;}
        }
    }
}

.homeslider {
    position: relative;
    .img {
        line-height: 0;
        img {width: 100%;}
    }
    .caption {
        position: absolute; width: 700px; top: 50%; left: 50%; color: $c_fff; z-index: 5; text-align: center;
        @include transform(translate(-50%,-50%));
        .title {font-size: 38px; line-height: 48px; margin-bottom: 20px;}
        .des {font-size: 16px; line-height: 20px; margin: 0 0 40px 0;}
    }
}
.col-logo {
    padding: 35px 0;
    .row {
        @include clearfix();
        .col {
            float: left; margin: 0 37px;
            a {
                position: relative; display: block; height: 70px;
                img {
                    position: relative; left: 50%; top: 50%; @include transform(translate(-50%,-50%));
                    @include transition(all .15s ease-in-out);
                }
                &:hover {
                    img {
                        @include transform(translate(-50%,-50%)scale(1.1));
                    }
                }
            }
        }
    }
}

.col-box {
    h2 {
        font-size: 19px; font-weight: 800; padding: 0 0 15px 0; position: relative;
        span {
            position: absolute; top: -35px; left: -5px; font-size: 80px; opacity: 0.1;
        }
        &.m_bot {margin-bottom: 25px;}
        &.m_bot-2 {margin-bottom: 10px;}
        &.yellow {
            color: $c_yellow;
        }
        &.bold {font-weight: bold;}
        &.light {font-weight: 300;}
        &.say {
            font-weight: 800; margin-left: 40px;
            span {color: $c_fff;}
            /* &:before {
                position:absolute; width: 160px; height: 90px; content: ""; top: -55px; left: -68px;
                background: url('../images/material/img-say.png') no-repeat center center;
            } */
        }
        &.land {
            padding-left: 30px;
            span {color: $c_green_4; left: 0px;}
        }
        &.land2 {
            padding-left: 23px;
            span {color: $c_green_4; left: 0;}
        }
        &.green {
            span {color:$c_green_4; margin-left: -25px;}
        }
        &.font800 {font-weight: 800;}
    }
    h3 {
        font-size: 32px; font-weight: 300; line-height: 38px; margin-bottom: 25px;
        &.green {color: $c_green_4;}
        &.v2 {font-size: 30px; font-weight: normal;}
    }
}
.bgimg {
    position: absolute; width: 100%; height: 100%; top: 0; right: 0; text-align: right;
    background-size:auto 100% !important; z-index: 1;
    &.v2 {
        text-align: left;
    }
}
.home-about {
    background: $c_7fc; position: relative;
    .container {padding: 108px 0 73px 0; z-index: 2;}
    .text {
        width: 565px; color: $c_fff; z-index: 2; position: relative;
        p {font-size: 16px; line-height: 30px; font-weight: 300; margin-bottom: 35px;}
        ol {
            margin: 0 0 0 0; padding: 0 0 30px 0;
            counter-reset: my-badass-counter;
            li {
                font-size: 16px; font-weight: bold; padding: 3px 0 3px 40px; position: relative;
                margin:0 0 15px 0; line-height: 20px;
                &:before {
                    position: absolute; left: 2px; top: 4px;
                    content: counter(my-badass-counter);
                    counter-increment: my-badass-counter;
                }
                &:after {
                    position: absolute; content: ""; left: 26px; top: 0; width: 1px; height: 100%;
                    background: $c_bfe;
                }
            }
        }
    }
    .bg_shd {left: 40%;width: 450px; position: absolute; right: auto; top: 0; bottom: 0; background: url('../images/material/shd_hijau.png') no-repeat; display: block; background-size: cover; z-index: 1;}
}

.listing-news {
    background: $c_f1f; position: relative;
    .container {padding: 135px 0 125px 0; z-index: 3;}
    .row {
        @include clearfix();
        a {
            display: block; height: 100%; position: relative;
        }
        .col {
            float: left; width: 22.7%; margin:0 3% 3% 0; background: $c_fff;
            @include borderRadius(5px); @include boxShadow(0px 0px 10px 0px $c_ddd);
            @include transition(all .1s ease-in-out);
            &:nth-child(4n+4) {margin-right: 0;}
            .inn {
                padding: 60px 25px 25px 25px; position: relative; z-index: 3;
                .date {font-size: 11px; color: $c_999; padding: 0 0 5px 0;}
                .title {
                    font-size: 28px; line-height: 36px; font-weight: 550; color: $c_green;
                    word-wrap: break-word;
                }
            }
            &.has-img {
                .img {
                    line-height: 0; position: relative; overflow: hidden; height: 175px; overflow: hidden;
                    @include borderRadius(5px 5px 0 0);
                    img {
                        @include borderRadius(5px 5px 0 0); width: 100%; height: 100%; object-fit: cover;
                        @include transition(all .18s ease-in-out);
                    }
                }
                .inn {
                    padding: 20px 25px 25px 25px;
                    .title {font-size: 16px; line-height: 24px; color: $c_333;}
                }
                &:hover {
                    .img {
                        img {@include transform(scale(1.1));}
                    }
                }
            }
            &.abs-img {
                .date {color: $c_fff;}
                .inn {
                    position: absolute; left: 0; bottom: 0; width: 100%;
                    @include boxSizing(border-box);
                    .title {
                        color: $c_fff;
                    }
                }
                .img {
                    position: absolute; width: 100%; height: 100%; z-index: 1; overflow: hidden;
                    @include borderRadius(5px);
                    img {
                        object-fit: cover; height: 100%; width: 100%;
                        @include transition(all .18s ease-in-out);
                        @include borderRadius(5px);
                    }
                    &:after {
                        position:absolute; width: 100%; height: 100%; left: 0; bottom: 0; content: "";
                        background: url('../images/material/overlay-thumb.png') repeat-x 0 bottom;
                        opacity: 0.7;
                        @include borderRadius(0 0 5px 5px);
                    }
                }
                &:hover {
                    .img {
                        img {@include transform(scale(1.1));}
                    }
                }
            }
            &:hover {
                @include boxShadow(0px 0px 10px 2px $c_ccc);
            }
        }
    }
    &.v2 {
        background: none; padding-bottom: 25px;
        .container {padding: 0 0;}
        .row {
            padding-bottom: 30px;
            .col {
                background: $c_f5f;
            }
        }
    }
    &.carousel {
       padding-top: 35px;
       .row {padding: 20px 0 0 0;}
       .slick-list {
            margin:0 70px 0 105px;
            .slick-slide {
                @include boxShadow(none);
                &.slick-active {
                    @include boxShadow(0px 0px 10px 0px $c_ddd);
                }
            }
        }
        .slick-arrow {
            background: none; width: 41px; height: 41px;
            &.slick-prev {
                background: url('../images/material/btn-arrow-pg-prev.png') no-repeat 0 0;
                &:after {display:none;}
                &.slick-disabled {background-position: 0 0;}
            }
            &.slick-next {
                background: url('../images/material/btn-arrow-pg-next.png') no-repeat -41px 0;
                &:after {display:none;}
                &.slick-disabled {background-position: -41px 0;}
            }
            &:hover {
               &.slick-prev {
                    background-position: -41px 0;
                    &.slick-disabled {background-position: 0 0;}
                }
               &.slick-next {
                background-position: 0 0;
                    &.slick-disabled {background-position: -41px 0;}
                }
            }
        }
    }
}
.box-news {
    .row {
        position: relative; overflow: hidden;
        @include clearfix();
        .col {
            float: left; width: 25%; position: absolute; overflow: hidden; border-top:1px solid $c_fff;
            border-left:1px solid $c_fff;
            @include boxSizing(border-box);
            a {display: block; position: relative;}
            .img {
                line-height: 0; position: relative; z-index: 1;
                img {@include transition(all .2s ease-in-out);}
                /* &:after {
                    position: absolute; width: 100%; height: 100%; left: 0; bottom: 0; content: "";
                    background: url('../images/material/overlay-thumb.png') repeat-x 0 bottom;
                } */
                &:before {
                    position:absolute; width: 100%; height: 30%; content: ""; left: 0; bottom: 0;
                    z-index: 3; display: block;
                    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.9));
                }
                img {width: 100%;}
            }
            .text {
                position: absolute; z-index: 3; bottom: 0; width: 100%; padding:0 27px 27px 27px;
                color: $c_fff;
                @include boxSizing(border-box);
                .title {font-size: 23px; font-weight: 300; margin-bottom: 10px; line-height: 24px;}
                .date {font-size: 11px; color: $c_999;}
            }
            &:nth-child(2) {top:0; right: 25%;}
            &:nth-child(3) {top: 0; right: 0;}
            &:nth-child(4) {bottom: 0; right: 25%;}
            &:nth-child(5) {bottom: 0; right: 0;}
            &.large {
                width: 50%; position: relative; border-left:0px solid $c_fff; cursor: pointer;
                a {
                    pointer-events: none;
                }
                .img {
                    &:after {
                        background: url('../images/material/overlay-thumb-large.png') repeat-x 0 bottom;
                    }
                }
                .text {
                    .title {font-size: 40px; line-height: 45px;}
                }
                &.vids {
                    a {
                        &:before {
                            position:absolute; width: 108px; height: 81px; content: ""; top: 50%; left: 50%;
                            background: url('../images/material/ico-play.png') no-repeat 0 0; z-index: 5;
                            @include transform(translate(-50%,-50%));
                        }
                    }
                }
            }
            &.vids {
                a {
                    &:before {
                        position:absolute; width: 50px; height: 38px; content: ""; top: 50%; left: 50%;
                        background: url('../images/material/ico-play.png') no-repeat 0 0; z-index: 5;
                        @include transform(translate(-50%,-50%));
                        background-size:cover;
                    }
                }
            }
            &:hover {
                .img {
                    img {
                        @include transform(scale(1.1));
                    }
                }
            }
        }
    }
}

.home-testi {
    background: $c_green_2; background-size: cover !important;
    .container {
        padding:120px 0 75px 0; min-height: 653px; @include boxSizing(border-box);
        &:after {
            position:absolute; width: 1346px; height: 458px; top: 50%; left: 50%; content: "";
            background: url('../images/material/bg-testi-in.png') no-repeat center center; visibility: visible;
            @include transform(translate(-50%,-50%));
        }
    }
    .slick-slider {
        z-index: 6; height: 330px; margin:0 30px;
        .slick-dots {
            bottom: -33px; text-align: right; padding: 0 190px 0 145px !important;
            li {
                button {
                    background: $c_c7c; opacity: 1;
                    @include borderRadius(50%);
                }
                &.slick-active {
                    button {background: $c_yellow;}
                }
            }
        }
    }
    .slick-arrow {display: none !important;}
    .txt-testi {
        padding: 50px 115px 0 115px; position: relative; z-index: 6; color: $c_fff;
        a {
            color: $c_fff; display: block;position: relative;
            &:after {
                position:absolute; width: 49px; height: 51px; content: ""; top: -12px; left: -70px;
                background: url('../images/material/ico-quote.png') no-repeat 0 0;
            }
        }
        .ts-title {
            font-size: 28px; font-weight: bold; font-style: italic; margin-bottom: 15px; position: relative;
            height: 58px; overflow: hidden;
        }
        .ts-des {
            font-size: 19px; font-style: italic; line-height: 30px; margin-bottom: 25px; font-weight: 400;
            height: 96px; overflow: hidden;
        }
        .ts-by {font-size: 19px; color: $c_green_3; font-weight: bold;}
    }
}


.home-cta {
    background: $c_3a4; color: $c_aab; position: relative;
    .container {padding: 128px 0 124px 0; z-index: 2;}
    .text {
        width: 485px; z-index: 9; position: relative;
        h3 {padding-bottom: 10px;}
        p {margin-bottom: 0;}
    }
    .bg_shd {width: 400px; position: absolute; right: 23%; top: 0; bottom: 0; background: url('../images/material/shd_hitam.png') no-repeat; display: block; background-size: cover;}
}

.home-address {
    background: $c_e8f;
    .container {padding: 70px 0 50px 0;}
    h2 {margin-bottom: 10px;}
    .col {
        float: left; @include boxSizing(border-box);
        &.des {
            width: 61.54%; padding: 0 0 0 0;
            font-size: 13px; font-weight: 300;
            p {line-height: 24px;}
        }
        &.address {
            width: 38.04%; padding: 0 75px 0 85px;
            h2 {color:$c_003;}
            .row {
                .item {
                    position: relative; padding: 0 0 5px 30px;
                    &:before {
                        position:absolute; width: 15px; height: 19px; content: ""; top: 0; left: 0;
                        background: url('../images/material/ico-map.png') no-repeat 0 0;
                    }
                    a {color: $c_333;}
                }
            }
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.breadcrumb {
    padding: 20px 0;
    ul {
        li {
            font-size: 16px; color: $c_a9a; display: inline-block; padding: 0 0 0 8px;
            a {color: $c_a9a; padding: 0 8px 0 0;}
            &:last-child {color: $c_004;}
        }
    }
}
.box-title {
    background: $c_green_2; min-height: 450px; margin-bottom: 80px;
    .container {padding: 90px 0;}
    h1 {font-size: 40px; font-weight: bold; color: $c_fff; letter-spacing: 2px; text-transform: uppercase;}
    &.v2 {min-height: 250px; margin-bottom: 80px;}
    p {
        color: $c_fff; font-size: 22px; margin: 10px 0 0 0; font-weight: 300;
    }
}

hr {height: 1px; border:none; background: $c_ccc; margin:10px 0 30px 0;}

.mid-content {
    min-height: 350px; padding-bottom: 65px; font-size: 16px;
    &.nopad {padding-bottom: 0;}
    &.thanks {
        .img {line-height: 0;}
    }
    p {line-height: 30px; font-weight: 400;}
    .banner-slider {
        margin: -336px 0 105px 0; @include boxShadow(0px 0px 15px 0px rgba(0, 0, 0, 0.4));
        position: relative;
        .caption {
            position: absolute; width: 100%; height: auto; left: 0; bottom: 0; color: $c_fff;
            font-size: 28px; line-height: 34px; text-transform: uppercase; font-weight: 800; z-index: 12;
            padding: 0 55px 60px 55px;
            @include boxSizing(border-box);
        }
        .img {
            line-height: 0; position: relative;
            img {width: 100%;}
        }
        &.v2 {
            margin-bottom: 95px;
        }
        &.v3 {
            margin-bottom: 65px;
        }
        &.v4 {
            margin:0 0 50px 0;
            .img {
                &:before {
                    position: absolute; width: 100%; height: 100%; content: ""; bottom: 0; left: 0;
                    background: url(../images/material/overlay-yellow.png) repeat-x 0 bottom;
                    z-index: 2; opacity: 0.7;
                }
            }
        }
        &.v5 {margin: -355px 0 65px 0;}
        &.creer {
            margin: 60px 0;
        }
    }
    .slider-testimoni {
        margin: -336px 0 105px 0; @include boxShadow(0px 0px 15px 0px rgba(0, 0, 0, 0.4));
        position: relative; margin-bottom: 0;
        .caption {
            position: absolute; width: 100%; height: auto; left: 0; bottom: 0; color: $c_fff;
            font-size: 28px; line-height: 34px; text-transform: uppercase; font-weight: 800; z-index: 12;
            padding: 0 55px 60px 55px;
            @include boxSizing(border-box);
        }
        .img {
            line-height: 0; position: relative;
            img {width: 100%;}
        }
        .slick-slider {overflow: visible;}
        .slick-arrow {
            top: 50%; @include transform(translateY(-50%));
            &.slick-prev {left: 0;}
            &.slick-next {right: 0;}
        }
        .slick-dots {top: 50%; height: 10px; margin-top: -25px; @include transform(translateY(-50%));}
        @include boxShadow(0px 0px 0px 0px rgba(0, 0, 0, 0));
        .img {
            margin: 0 0 50px 0; position: relative;
            @include boxShadow(0px 0px 15px 0px rgba(0, 0, 0, 0.4));
        }
        /* .custom-arrow {
            position: absolute; z-index: 155; top: 50%; cursor: pointer;
            width: 90px; height: 60px; background: rgba(0,0,0, 0.5); padding: 0;
            border:0; cursor: pointer; color: $c_fff; z-index: 5; text-indent: -999999px;
            @include transform(translateY(-50%));
            &:after {
                position:absolute; width: 42px; height: 42px; content: ""; top: 50%;
                @include transform(translateY(-50%));
            }
            &.arr-prev {
                left: 0; @include borderRadius(0 30px 30px 0);
                &:after {
                    right:10px;
                    background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;
                }
            }
            &.arr-next {
                right: 0; @include borderRadius(30px 0 0 30px);
                &:after {
                    left:10px;
                    background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;
                }
            }
            &:hover {
                &.arr-prev {
                    &:after {background:url('../images/material/btn-arrow-prev-yellow.png') no-repeat 0 0;}
                    &.slick-disabled {
                        &:after {background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;}
                    }
                }
                &.arr-next {
                    &:after {background:url('../images/material/btn-arrow-next-yellow.png') no-repeat 0 0;}
                    &.slick-disabled {
                        &:after {background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;}
                    }
                }
            }
            &.slick-disabled {cursor: default; opacity: 0.5;}

        }
        .custom-paging {
            position: absolute; z-index: 155; width: 100%; height: 10px; left: 0; bottom: 15px;
            background: $c_ccc;
            @include transform(translateY(-50%));
        } */
    }
    .paging {
        text-align: center;
        div {padding: 24px 0 0 0; border-top: 1px solid $c_d6d;}
        a {
            display: inline-block; font-size: 20px; color: $c_cfc; padding: 3px 20px; position: relative;
            vertical-align: middle; font-weight: bold;
            @include transition(none);
            &:after {
                position:absolute; width: 1px; height: 0; content: ""; background: $c_green_7;
                right: 25%; top: 50%;
                @include transform(translateY(-50%));
                @include transition(all .2s ease-in-out);
            }
            &:hover, &.active {
                color: $c_green_7;
                &:after {
                    height:100%;
                }
            }
            &.prev, &.next {
                width: 41px; height: 41px; text-indent: -999999px; margin: 0 10px; padding: 0;
                &:after {display:none;}
            }
            &.prev {
                background: url('../images/material/btn-arrow-pg-prev.png') no-repeat 0 center;
                &:hover {background-position:-41px center;}
            }
            &.next {
                background: url('../images/material/btn-arrow-pg-next.png') no-repeat -41px center;
                &:hover {background-position:0 center;}
            }

        }
        &.no-border {
            div {border-top: 0;}
        }
    }

    .description {
        padding: 0 70px;
        &.col-box {padding: 0 0;}
        h3 {
            font-size: 40px; color: $c_green_4; line-height: 50px; font-weight: 300; margin-bottom: 30px;
            &.v2 {font-size: 30px; font-weight: 800; line-height: 32px;}
            &.v3 {color: $c_7fc; font-size: 35px; margin: 0 0 20px 0;}
        }
        h4 {
            font-size: 20px; color: $c_yellow_2; line-height: 28px; margin-bottom: 25px;
            &.green {color: $c_green; font-weight: 300; margin-bottom:20px;}
        }
        h5 {
            font-size: 16px; color: $c_green_4; line-height: 20px; margin-bottom: 15px; font-weight: 800;
            span {display: inline-block; vertical-align: middle; padding: 0 15px 0 0;}
            &.green_2 {color: $c_green_2;}
            &.nobot {margin-bottom: 0;}
        }
        h6 {
            font-size: 16px; color: $c_green_4; line-height: 20px; margin-bottom: 15px; font-weight: 800;
            &.small {font-size: 13px; color: $c_green_5;}
        }
        p {
            margin-bottom: 30px;
            &.small {font-size: 14px; font-weight: 300; line-height: 20px;}
            &.med {font-size: 16px; font-weight: 300; line-height: 24px;}
        }
        a {
            color: $c_green_2; font-weight: 600;
            &.btn {
                &.white {color: $c_fff;}
            }
        }
        ul {
            font-weight: 400; padding: 0 0 30px 0; margin-left:10px;
            li {
                position: relative; padding: 0 0 10px 30px; line-height: 35px;
                &:before {
                    position: absolute; width: 10px; height: 10px; content: ""; left: 0; top: 12px;
                    background: url('../images/material/bullet-1.png') no-repeat 0 0;
                }
                ul {
                    padding: 5px 0 10px 0; margin: 0 0 0 25px;
                    li {
                        padding: 0 0 0 24px;
                        &:before {
                            background: url('../images/material/bullet-2.png') no-repeat 0 0;
                        }
                    }
                }
            }
            &.space {margin-left: 45px;}
        }
        .htop {
            @include clearfix; padding: 0 0 30px 0;
            h5 {float: left;}
            a {float: right; color: $c_999; font-weight: 500; font-size: 14px;}
        }
        .table-std {padding: 25px 0 80px 0;}
        table {
            width: 100%; border:1px solid $c_d1d; font-weight: 300; text-align: center;
            thead {
                tr {
                    th {
                        vertical-align: middle; padding: 17px 10px; font-size: 18px; font-weight: 500;
                        background: $c_green_8; border:1px solid $c_d1d;
                        &.yellow {background: $c_yellow_4;}
                    }
                    &.med {
                        th {font-size: 16px;}
                    }
                }
            }
            tbody {
                tr {
                    td {vertical-align: middle; padding: 17px 10px; font-size: 16px; border:1px solid $c_d1d;}
                }
            }
            tfoot {
                tr {
                    td {
                        vertical-align: middle; padding: 17px 10px; font-size: 16px; border:1px solid $c_d1d;
                        background: $c_yellow_3;
                    }
                }
            }
            &.styled-2 {
                border:0; text-align: left;
                thead {
                    tr {
                        th {
                            padding: 17px 15px;
                            background: $c_yellow_3; border:0; font-size: 16px; color: $c_green;
                            line-height: 24px;
                            &:nth-child(1) {padding-left: 25px;}
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            padding: 18px 15px; border:0;
                            &:nth-child(1) {padding-left: 25px;}
                        }
                    }
                }
            }
            &.width-2 {
                thead {
                    th {
                        &:nth-child(1){width: 13%;}
                        &:nth-child(2){width: 11%;}
                        &:nth-child(3){width: 15%;}
                        &:nth-child(4){width: 20%;}
                        &:nth-child(5){width: 10%;}
                        &:nth-child(6){width: 10%;}
                        &:nth-child(7){width: 10%;}
                        &:nth-child(8){width: 10%;}
                    }
                }
                tbody {
                    tr {
                        &:nth-child(even) {td{background:$c_green_9;}}
                    }
                }
            }
        }
        .indes {padding: 10px 35px;}
        .box-visi {
            position: relative; padding: 15px 0 0 0;
            @include clearfix();
            p {margin-bottom: 30px;}
            &:before {
                position:absolute; width:1px; height: 95%; display: block; background: $c_ccc;
                left: 50%; top: 0;
                @include transform(translateX(-50%));
            }
            .col {
                float: left; width: 50%; padding: 0 85px 0 0;
                @include boxSizing(border-box);
                &:nth-child(2n+2) {padding: 0 0 0 85px;}
            }
        }
        .photo-profile {
            @include clearfix(); padding: 0 0 40px 0;
            .col {
                float: left; @include boxSizing(border-box);
                &.img {
                    width: 17.6%;
                    @include borderRadius(50%);
                    img {@include borderRadius(50%);}
                }
                &.text {
                    width: 82.4%; padding: 45px 0 0 45px;
                    h3 {font-size: 28px; margin: 0 0 5px 0;}
                    h4 {font-size: 18px; font-weight: 550; margin-bottom: 20px; line-height: 22px;}
                    p {font-size: 14px; line-height: 16px;}
                    &.v2 {padding-right:330px;}
                }
            }
        }
        .testi {
            margin: 30px 0;
            h2.font800 {margin-bottom: 40px;}
            .photo-profile {
                .col.text.v2 {
                    padding-right: 20px;
                     p{line-height: 24px; margin-bottom: 20px;}
                }
            }
        }
        .col-bottom {
            margin:0 0 0 0; padding: 80px 0 0 0; position: relative;
            @include clearfix();
            &:before {
                position:absolute; width: 100%; height: 1px; background: $c_aca; top: 20px; left: 0;
            }
            .btn {color: $c_fff;}
            .row-list {
                padding: 0 0 0 92px;
                h6 {
                    font-size: 13px; color: $c_green_5; font-weight: 800; position: relative;
                    margin:0 0 20px 0;
                    &:before {
                        position:absolute; width: 40px; height: 3px; background: $c_green_2; content: "";
                        left: -60px; top: 5px;
                    }
                }
                .col {margin: 0 0 50px 0;}
                .btn-download {
                    font-size: 13px; color: $c_yellow; font-weight: bold; position: relative;
                    padding: 0 0 0 20px;
                    &:before {
                        position:absolute; width: 11px; height: 12px; content: ""; left: 0; top: 3px;
                        background: url('../images/material/ico-download.png') no-repeat center center;
                    }
                }
            }
            .row {
                @include clearfix(); position: relative;
                &:before {
                    position:absolute; width: 1px; height: 100%; content: ""; display: block;
                    background: $c_ddd; left: 39%; @include transform(translateX(-50%));
                }
                .cl {
                    float: left; @include boxSizing(border-box); position: relative;
                    &.fl {width: 39%; padding: 0 48px 0 0;}
                    &.fr {width: 61%; padding: 0 0 0 53px;}
                }
            }
        }
        .find-more {
            padding: 0 0 0 30px;
            a {
                display: inline-block; position: relative; padding: 0 0 0 60px; font-size: 13px;
                color: $c_green_5; font-weight: 550; margin: 0 0 5px 0;
                &:before {
                    position:absolute; content: ""; width: 40px; height: 3px; left: 0; top: 50%;
                    background: $c_green_2;
                    @include transform(translateY(-50%));
                }
            }
        }

        .paging {
            div {
                a {
                    color: $c_999;
                    &:hover, &.active {
                        color:$c_green_7;
                    }
                }
            }
        }
        &.nostyled-table {
            .table-std {padding: 0 0 50px 0;}
            table {
                width: 55%; border:0;
                tbody {
                    tr {
                        td {
                            border:0; text-align: left; padding: 10px; font-weight: 400;
                            &:first-child {color:$c_yellow; font-weight: 500; width: 35%;}
                            h5 {margin-bottom: 0;}
                        }
                    }
                }
            }
        }
    }
    .col-university, .col-partner {
        .row {
            border-bottom: 1px solid $c_ccc; margin-bottom: 60px;
            .col {
                padding-bottom: 20px;
                h5 {margin-bottom: 30px;}
                div {
                    @include clearfix();
                    a {
                        float: left; width: auto; text-align: center; height: 112px;
                        position: relative; margin: 0 0 20px 0; padding: 0 75px 0 0;
                        @include boxSizing(border-box);
                        img {
                            position: relative; top: 50%;
                            @include transition(all .2s ease-in-out);
                            @include transform(translateY(-50%));
                        }
                        &:hover {
                            img {
                                @include transform(translateY(-50%) scale(1.1));
                            }
                        }
                    }
                }
            }
            &.col-5 {
                .col {
                    a {width: 20%; padding: 0 30px 0 0;}
                }
            }
            &:last-child {border-bottom: 0; margin-bottom: 0;}
        }
    }
    .box-landing {
        .row {
            margin-bottom: 80px;
            @include clearfix();
            .col {
                @include boxSizing(border-box);
                &.txt {float: left; width: 57.2%; padding: 95px 115px 0 0;}
                &.img {float: right; width: 42.74%;}
                .ld-des {
                    .ct {
                        padding: 0 0 0 65px;
                    }
                }
                .land-banner {
                    position: relative;
                    .caption {
                        position: absolute; width: 100%; padding: 0 35px; z-index: 4; left: 0; bottom: 40px;
                        font-size: 24px; font-weight: 800; color: $c_fff;
                        @include boxSizing(border-box);
                        &.v2 {
                            font-size: 30px; line-height: 36px;
                            a {
                                display: block; font-size: 18px; line-height: 18px; color: $c_fff;
                                margin: 20px 0 0 0; text-decoration: underline;
                                &:hover {text-decoration:none;}
                            }
                        }
                    }
                    .ld-row {position: relative;}
                    .slide {
                        position: relative;
                        .img {
                            position: relative; z-index: 1; line-height: 0;
                            &:before {
                                position:absolute; width: 100%; height: 100%; content: ""; bottom: 0; left: 0;
                                background:url('../images/material/overlay-land.png') repeat-x 0 bottom;
                                z-index: 2;
                            }
                            img {position: relative; z-index: 1; width: 100%; height: 100%; object-fit: cover;}
                            &.v2 {
                                &:before {
                                    background: url('../images/material/overlay-land-2.png') repeat-x 0 bottom;
                                }
                            }
                        }
                    }
                    .slick-initialized {
                        .slick-list {
                            &:after {
                                position:absolute; width: 226px; height: 92px; content: ""; bottom: -1px; right: -1px;
                                background:url('../images/material/ribbon.png') repeat-x 0 bottom;
                                z-index: 2;
                            }
                        }
                        .slick-slide {position: relative;}
                        .slick-arrow {
                            top: 35px; background: none; width: 42px; height: 42px;
                            @include transform(translate(0));
                            &.slick-prev {
                                left: 100%; margin-left: -125px;
                                &:after {left:0;}
                            }
                            &.slick-next {
                                right: 30px;
                                &:after {left: 0;}
                            }
                        }
                    }
                    &.v2 {
                        .slick-initialized {
                            .slick-list {
                                &:after {display:none;}
                            }
                        }
                    }
                }
            }
            &:nth-child(even) {
                .col {
                    .ld-des {
                        .ct {padding: 0;}
                    }
                    &.txt {float: right; padding: 95px 0 0 115px;}
                    &.img {float: left;}
                }
            }
        }
    }

    .grid-category {
        padding: 50px 0 0 0;
        .row {
            @include clearfix();
            .col {
                float: left; width: 50%; padding: 0 15px 0px 0; margin: 0 0 30px 0; position: relative;
                @include boxSizing(border-box);
                &:nth-child(2n+2) {padding:0 0 0 15px;}
                a {display: block; position: relative; overflow: hidden;cursor: default;}
                .caption {
                    position: absolute; width: 100%; padding:0 105px; z-index: 3; left: 50%; top: 50%;
                    text-align: center; font-size: 32px; line-height: 40px; color: $c_fff; font-weight: 800;
                    text-transform: uppercase; z-index: 6;
                    @include boxSizing(border-box); @include transform(translate(-50%,-50%));
                }
                .img {
                    line-height: 0; position: relative;
                    &:before {
                        position:absolute; width: 100%; height: 100%; content: ""; left: 0; bottom: 0;
                        opacity: 0; z-index: 5;
                        @include transform(scale(0.9));
                        @include transition(all .15s ease-in-out);
                    }
                    &:after {
                        position:absolute; width: 100%; height: 100%; content: ""; left: 0; bottom: 0;
                        background: url('../images/material/overlay-land-gal.png') repeat-x center bottom;
                    }
                    img {
                        position: relative; height: 100%; width: 100%; object-fit: cover;
                        @include transition(all .15s ease-in-out);
                    }
                }

                &.first {
                    .caption {
                        width: 50%; padding: 0 65px; left: 0; font-size: 25px; line-height: 30px;
                        @include transform(translate(0%,-50%));
                        .des {
                            font-size: 16px; line-height: 24px; font-weight: 300; text-transform: capitalize;
                            padding: 10px 0 0 0;
                        }
                    }
                    .img {
                        text-align: right;
                        .arr {
                            position:absolute; width: 20px; height: 100%; content: ""; top: 0; left: 51%;
                            background: url('../images/material/land-gal-arrow.png') no-repeat center center; z-index: 3;
                            @include transform(translateX(-50%)); background-size: cover;
                            /* &:before,
                               &:after {
                                   position:absolute; width:100%; content: ""; left: 0; background:$c_green_6;
                               }
                               &:before {top: 0; height: 46.5%; }
                               &:after {bottom: 0; height: 46.5%; } */
                        }
                        &:after {background:$c_green_6; width: 50%;}
                    }
                }
                &.green {
                    .img {
                        &:before {background:rgba(19,109,111, 0.9);}
                    }
                }
                &.red {
                    .img {
                        &:before {background:rgba(176,49,83, 0.9);}
                    }
                }
                &.yellow {
                    .img {
                        &:before {background:rgba(195,170,21, 0.9);}
                    }
                }
                &.purple {
                    .img {
                        &:before {background:rgba(58,82,149, 0.9);}
                    }
                }
                &:hover {
                    .img {
                        &:before {
                            opacity: 1; @include transform(scale(1));
                        }
                        img {@include transform(scale(1.1));}
                    }
                }
            }
        }
        &.col_3 {
            .row {
                padding: 25px 0 50px 0;
                .col {
                    width: 33.333333%; margin: 0 0 0 0; padding: 0;
                    .caption {
                        font-size: 20px; line-height: 24px; padding: 0 40px;
                        @include transition(all .5s ease-out);
                        .des {
                            padding: 7px 0 0 0;
                            font-size: 15px; line-height: 20px; text-transform: initial; font-weight: 500;
                            height: 0; overflow: hidden; opacity: 0;
                            @include transition(all .2s ease-out);
                            @include transform(scale(0.8));
                        }
                    }
                    &:hover {
                        .caption {
                            .title {display: none;}
                            .des {
                                height: auto; opacity: 1; padding: 0;
                                @include transform(scale(1));
                            }
                        }
                        .img {
                            &:before {
                                background:rgba(195, 170, 21, 0.9);
                            }
                        }
                    }
                }
            }
        }
    }

    .list-life {
        .col {
            @include clearfix();
            margin: 0 0 30px 0; padding: 0 0 30px 0; border-bottom: 1px solid $c_aca;
            .fl {
                h5 {margin: 10px 0 0 0;}
            }
            .fr {
                .btn {color: $c_fff;}
            }
            &:last-child {margin-bottom: 25px;}
        }
        &.v2 {
            padding: 30px 0 0 0;
            .row {padding: 20px 0 0 0;}
            .col {
                .fl {
                    h5 {margin: 0;}
                    .locate {font-size: 14px; font-weight: 300; padding: 4px 0 0 0;}
                }
            }
        }
    }
    .list-gen {
        .col {
            @include clearfix();
            margin: 0 0 30px 0; padding: 20px 0 30px 0; border-top: 0px solid $c_aca;
            .btn {color: $c_fff; text-align: center;}
            .cl {
                padding: 0 0 0 0;
                @include boxSizing(border-box);
            }
            .fl {
                width: 50%; @include boxSizing(border-box);
                h5 {margin: 10px 0 0 0;}
            }
            .fr {
                width: 50%; padding: 0 0 0 5%; @include boxSizing(border-box);
                text-align: right;
            }
            &:last-child {margin-bottom: 25px;}
        }
    }

}


.styledSelect {
    position: relative; display: inline-block; min-width: 200px;
    select {
        option {color: $c_333;}
    }
    &.v2 {
        &:after {
            position:absolute; width: 16px; height: 100%; top: 0; right: 10px; content: "";
            background:url('../images/material/arr-acc.png') no-repeat center center;
        }
        select {
            position: relative; z-index: 2; width: 100%; background:none; width: 100%;
            padding: 0 40px 0 20px; height: 40px; line-height: 40px; border: 1px solid $c_ddd;
            font-size: 14px; cursor: pointer; font-weight: 550;
            @include boxSizing(border-box);
        }
    }
}

.filtering {
    @include clearfix(); margin-bottom: 50px;
    .cl {display: inline-block; vertical-align: top;}
    label { height: 40px; line-height: 40px; margin-right: 20px;}
}

.col-form {
    min-height: 680px; background: $c_7fc; text-align: center; background-size: cover !important;
    .container {
        padding: 75px 160px; @include boxSizing(border-box);
        &:before {
            position:absolute; width: 137px; height: 169px; content: ""; top: -115px; right: -75px;
            background:url('../images/material/ribbon-form.png') no-repeat 0 0;
        }
    }
    h2 {color: $c_green;}
    h3 {color: $c_fff;}
    p {color: $c_fff; font-weight: 300; font-size: 20px; line-height:30px;}
    .form-std {
        margin:0 auto; padding:65px 0 0 0; position: relative;
        @include clearfix();
        .col {
            float: left; width: 50%; padding: 0 44px 0 0; margin: 0 0 40px 0; text-align: left; position: relative;
            @include boxSizing(border-box);
            @include placeholder {color: $c_fff;}
            &:nth-child(2n+2) {padding-left: 44px; padding-right: 0;}
            .field {
                position: relative;
                span {
                    position: absolute; padding-top: 5px; color: red; font-size: 11px;
                    display: block; width: 100%; left: 0; top: 100%;
                }
                &.other-val{
                    padding-top: 15px; display: none;
                }
            }
            .label {
                position: absolute; top: 0; left: 0; color: $c_fff;
                height: 40px; line-height: 40px; font-size: 13px;
            }
            .anim-label {
                position: relative; padding: 0 0 0 0; height: 40px; line-height: 40px; width: 100%;
                background: none; border:0; border-bottom: 1px solid $c_ccc; font-size: 16px; color: $c_fff;
                @include boxSizing(border-box);
                option {color: $c_333;}
                &.error {border-color: red;}
                @include andplaceholder{color: #fff; opacity: 0;}
            }
            select {
                cursor: pointer;
                &.anim-label {
                    background: url('../images/material/ico-arr.png') no-repeat 97% center;
                }
            }
            textarea {
                color: $c_fff; background: none; border:none; border-bottom: 1px solid $c_ccc; resize: none;
                width: 100%; padding: 15px 0 15px 0; min-height: 100px; font-size: 13px; line-height: 16px;
                @include boxSizing(border-box);
                &.txtarea {line-height: 16px; margin: 15px 0 15px 0;}
            }
            &.with-padding,
            &.btn-submit {
                padding: 0 0 0 44px;
            }

            &.col-captcha{
                clear:both;
            }

            &.no-padding{
                padding:0;
            }
            &.col-button{
                padding-top:21px;
            }

            .field {
                &.focus {
                    .anim-label {
                        @include andplaceholder{opacity: 1; color: #fff;}
                    }
                }
            }
        }
        &.std-2 {
            .col {
                padding: 0; margin: 0 0 28px 0;
                @include placeholder {color: $c_666;}
                label {display: block; margin: 0 0 10px 0; font-size: 13px;}
                &.full {width: 100%;}
                input {
                    width: 100%; height: 40px; line-height: 40px; padding: 0 40px 0 0; border:0;
                    border-bottom: 1px solid $c_ccc; font-size: 16px;
                    &.datepicker {
                        background: url('../images/material/ico-date.png') no-repeat right 50%;
                    }
                    @include boxSizing(border-box);
                }
                &.col_7 {
                    width: 70%;
                    select {border: none; border-bottom: 1px solid $c_ccc; width: 100%; font-size: 16px; background: url('../images/material/arr-acc.png') no-repeat 100% center;padding-right: 40px; padding-left: 0;}
                }
            }
        }
    }
    .place-result {display: none;}
    &.v2 {
        background: none; min-height: 280px; text-align: left;
        h3 {font-size: 28px; line-height: 32px; font-weight: bold; margin-bottom: 5px;}
        h4 {font-weight: 500;}
        p {color: $c_333;}
        .form-std {
            width: 100%; padding: 25px 0 0 0;
            .col {
                .label {color: $c_333; font-size: 16px;}
                .anim-label {color: $c_333;}
                select {
                    &.anim-label {
                        background: url(../images/material/ico-arr-2.png) no-repeat 97% center;
                    }
                }
                .field {
                    &.focus {
                        .label {font-size: 13px;}
                        @include placeholder{opacity: 1; color: #333;}
                    }
                }
            }
        }
    }
}

.col-acc {
    padding: 10px 0 0 0;
    .acclist {
        .acc-title {
            position: relative; font-weight: 600; color: $c_999; padding: 24px 40px 24px 0;
            border-bottom: 1px solid $c_ccc; cursor: pointer;
            &:after {
                position:absolute; width: 16px; height: 10px; content: ""; top: 25px; right: 18px;
                background: url('../images/material/arr-acc.png') no-repeat center center;
                @include transition(all .2s ease-in-out);
            }
            &.open {
                color: $c_green_4;
                &:after {
                    @include transform(rotate(-180deg));
                }
            }
            a {color: $c_999;}
        }
        .acc-descrip {
            padding:25px 0 25px 0;
            ul {
                margin: 0 0 0 0; padding: 0 0 30px 0; font-weight: 300;
                li {
                    font-size: 16px; padding: 3px 0 3px 25px; position: relative;
                    margin:0 0 15px 0; line-height: 20px; list-style: none;
                    &:before {left:0; top: 8px;}
                }
            }
            p {margin-bottom: 20px;}
            ol {
                margin: 0 0 0 0; padding: 0 0 30px 0; font-weight: 300;
                counter-reset: my-badass-counter;
                li {
                    font-size: 16px; padding: 3px 0 3px 40px; position: relative;
                    margin:0 0 15px 0; line-height: 20px;
                    &:before {
                        position: absolute; left: 2px; top: 4px;
                        content: counter(my-badass-counter);
                        counter-increment: my-badass-counter;
                    }
                    &:after {
                        position: absolute; content: ""; left: 26px; top: 0; width: 1px; height: 100%;
                        background: $c_333; opacity: 0.5;
                    }
                }
                &.styled-2 {
                    @include clearfix();
                    width: 50%; padding: 8px 0 0 40px;
                    li {float: left; width: 40%;}
                }
            }
        }
    }

    &.v2 {
        .acc-title {
            padding: 24px 65px 24px 30px; cursor: default; font-weight: 800; color: $c_green_4;
            border-color: $c_aca;
            &:after {
                right:38px; display: none;
                background: url('../images/material/arr-green.png') no-repeat center center;
            }
        }
        .acc-descrip {
            padding:25px 30px;
        }

    }
}

.box-life {
    padding: 40px 0;
    .row {
        @include clearfix(); padding: 20px 0 0 0;
        .col {
            float: left; width: 31.333333%; margin: 0 3% 3% 0;
            &:nth-child(3n+3) {margin-right: 0;}
        }
    }
}

.tab-container {
    position: relative;
    .nav-tab {
        padding: 0 0 50px 0;
        .parent-mob {
            display: none;
            position: relative; height: 50px; line-height: 50px; font-size: 16px;
            color: $c_yellow; background:$c_green_10; font-weight: bold;  padding: 0 35px 0 15px;
            @include boxSizing(border-box); cursor: pointer;
            &:after {
                position:absolute; width: 13px; height: 8px; content: ""; top: 50%; right: 11px;
                background:url('../images/material/arr-yellow.png') no-repeat 0 0;
                @include transform(translateY(-50%));
            }
        }
        ul {
            padding: 0; margin: 0;
            li {
                display: inline-block; position: relative; line-height: 16px; padding: 0;
                width: auto; text-align: center; float: none; vertical-align: middle;
                &:before {display:none;}
                &:after {
                    position:absolute; width: 1px; height: 13px; content: ""; right: 0; top: 16px;
                    background: $c_959;
                }
                a {
                    font-size: 16px; color: $c_999; position: relative; padding:15px 25px 15px 25px; display: block;
                    position: relative; text-align: center;
                    &:after {
                        position:absolute; width: 0; height: 3px; content: ""; background: $c_yellow;
                        left: 50%; bottom: 0; @include transform(translateX(-50%));
                        @include transition(all .18s ease-in-out);
                    }
                }
                &:last-child {
                    &:after {display:none;}
                }
                &:hover,
                &.active {
                    &:after {display:none;}
                    a {
                        color: $c_fff; background:$c_green;
                    }
                }
            }
        }
        .slick-list {margin: 0 40px;}
        .slick-arrow {
            width: 30px; height: 30px; @include borderRadius(30px); z-index: 2;
            &:after {width: 20px; height: 20px; background-size: 100% 100%;}
            &.slick-prev {
                left: 0px;
                &:after {left:5px;}
            }
            &.slick-next {
                right: 0px;
                &:after {left:5px;}
            }
            &.slick-disabled {
                opacity: 0.4;
                &:after {width: 20px; height: 20px; background-size: 100% 100%;}
            }
        }
    }
     &.center {
        .nav-tab {
            ul {
                li {
                    text-align: center;
                    &:after {top:50%; @include transform(translateY(-50%));}
                    a {line-height: 18px;}
                }
            }
        }
    }
}

.listing-headschool {
    .row {
        position: relative; border-bottom: 1px solid $c_aca; margin-bottom: 40px;
        @include clearfix();
        .abs {
            position: absolute; top: 40%; right: 0;
            @include transform(translateY(-50%));
        }
        &:last-child {border-bottom: 0; margin-bottom: 0;}
    }
}

.col-gallery {
    .nav-gal {
        @include clearfix(); margin-bottom: 50px;
        a {
            float: left; font-size: 20px; font-weight: bold; color: $c_999; margin: 0 60px 0 0;
            &:hover, &.active {
                color:$c_7fc;
            }
        }
    }
    .row {
        @include clearfix(); padding: 0 0 15px 0;
        .col {
            float: left; width: 31.333333%; margin: 0 3% 3% 0; position: relative; overflow: hidden;
            &:nth-child(3n+3) {margin-right: 0;}
            a {
                color: $c_fff; display: block; height: 100%;
                .text {
                    position: absolute; width: 100%; z-index: 4; left: 0; bottom: 0;
                    padding: 25px 22px;
                    @include boxSizing(border-box);
                    .caption {
                        font-size: 18px; text-transform: uppercase; font-weight: bold; line-height: 24px; height: 48px; overflow: hidden;
                        margin-bottom: 5px;
                    }
                    .count {font-size: 14px;}
                }
                .img {
                    line-height: 0; position: relative;
                    img {
                        position: relative; z-index: 1; @include transition(all .18s ease-in-out);
                        width: 100%; height: 100%; object-fit: cover;
                    }
                    &:before {
                        position:absolute; width: 100%; height: 100%; content: ""; left: 0; bottom: 0;
                        background: url('../images/material/bg_shadow.png') repeat-x 0 bottom;
                        z-index: 3; display: block;
                    }
                    .hov_btn {
                        @include transition(all 0.3s ease-out);position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: block; z-index: 3; text-align: center; opacity: 0; @include transform(scale(1.15));
                        i {font-style: normal; position: absolute;text-transform: uppercase; top: 50%; @include transform(translate(-50%)); display: block; left: 50%;min-width: 190px; width: auto;}
                    }
                }
                &:hover {
                    .img {
                        img {
                            @include transform(scale(1.1));
                        }
                        .hov_btn {@include transform(scale(1));@include transition(all 0.3s ease-out); opacity: 1;}
                    }
                }
            }
            &.video {
                a {
                    &:before {
                        width:60px; height: 46px; content: ""; z-index: 5; padding: 0px;
                        position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto;
                        background:rgba(0,0,0, 0.5) url('../images/material/ico-play.png') no-repeat center center;
                        background-size:85%; @include borderRadius(15px);
                    }
                    .img {
                        .hov_btn {display: none;}
                    }
                }
            }
        }
        &.havePopup {
            .col {
                a {
                    .img {
                        &:before {opacity: 0.65;}
                    }
                }
            }
        }
    }
    &.detail {
        .row {
            .col {
                 a {
                    .img {
                        &:before {display:none;}
                    }
                 }
            }
        }
    }
}

.careermap {
    header {
        background: none;
        &.float {background: $c_fff;}
    }
    footer {display: none;}
    .middle {min-height: 900px; max-height: 900px; @include boxSizing(border-box);}
    .map-location {
        margin: 0 70px; position: relative; height: 400px;
        @include boxSizing(border-box);
    }
    .des-map {
        text-align: center; position: absolute; width: 100%; top: 0;
        @include boxSizing(border-box);
        .title {
            font-size: 48px; line-height: 70px; text-shadow: 1px 3px 5px $c_333;
            padding:50px 130px 30px 130px;
        }
        p {font-weight: 500;}
    }
    .point-map {
        position: absolute; margin: 60px 15px 0 15px; width: 100%;
        bottom: 0;
        a {
            position: absolute; padding: 80px 10px 0 10px; font-size: 20px;
            color: $c_333; font-weight: bold; text-align: center;
            @include transition(all .15s ease-in-out);
            &.jkt {top: 0; left: 0;}
            &.bali {top: 120px; right: 345px;}
            &:after {
                position: absolute; width: 52px; height: 64px; content: ""; left: 50%; top:0;
                background: url('../images/material/ico-map-med.png') no-repeat center 0;
                @include transform(translateX(-50%));
                @include transition(all .15s ease-in-out);
            }
            &:hover {
                &:after {
                    @include transform(translateX(-50%) scale(1.1));
                }
            }
        }
    }
}

.inner-contact {
    padding:0 105px;
    .list-address {
        .row {
            margin-bottom: 20px;
            @include clearfix();
            border-bottom: 1px solid $c_ccc; margin-bottom: 60px;
            .col {
                float: left; @include boxSizing(border-box);
                &:nth-child(1) {width: 23.75%; padding: 0 65px 0 0;}
                &:nth-child(2) {width: 76.25%;}
                h3 {
                    font-size: 28px; color: $c_green_4; font-weight: 800; margin-bottom: 10px; line-height: 30px;
                    &.v2 {font-size: 24px; line-height: 28px;}
                }
                h4 {font-size: 20px; color: $c_yellow_2; font-weight: 550;}
                p {
                    font-size: 18px; line-height: 28px; font-weight: normal; margin-bottom: 0;
                    .green {font-size: 20px; color: $c_green_4; font-weight: bold;}
                }
                a {color: $c_333;}
                .row {
                    border:none; margin-bottom: 40px;
                    .cl {float: left; width: 50%; @include boxSizing(border-box); padding: 0 90px 0 0;}
                }
            }
            &:last-child {border-bottom: 0;}
        }
    }
    .list-map {
        margin-bottom: 75px;
        h3 {font-size: 22px; font-weight: 800; color: $c_green_4; margin: 0 0 40px 0;}
        .row {
            @include clearfix();
            .col {
                float: left; width: 31.33333%; margin:0 3% 0 0;
                &:last-child {margin-right:0;}
                p {margin-bottom: 5px; color: $c_green_4;}
                .map {
                    height: 440px;
                    iframe{
                        width: 100%; height: 440px;
                    }
                }
            }
        }
    }
    .addmission {
        padding: 60px 25px 25px 25px; border-top: 1px solid $c_aca;
        h2 {margin-bottom: 50px;}
        .row {
            @include clearfix();
            margin: 0 0 30px 0;
            .col {
                float: left; width: 40%; padding: 0 55px 0 0; font-size: 20px;
                font-weight: bold;
                @include boxSizing(border-box);
                a {color: $c_333;}
                &:first-child {color: $c_green_4;}
                &:nth-child(2n+2) {
                    width: 60%;
                    padding: 0 0 0 55px; font-size: 18px;
                }
                span {font-size: 16px; color: $c_999; display: block; padding: 13px 0 0 0;}
            }
        }
    }

    &.v2 {
        padding:100px 0 20px 0;
        .list-address {
            .row {
                border:0; margin: 0 0 20px 0;
                .col {
                    .row {
                        .cl {padding: 0;}
                    }
                }
            }
        }
    }
}


body.lock {overflow-y: hidden;}
.wrap_popup {
    background: rgba(0,0,0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    top: 0;
    left: 0;
    overflow-y: auto;
    .pop_inner {
        display: table;
        height: 100%;
        width: 100%;
        position: absolute;
        .middle_pop {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .overlay_bg {
        position: absolute; width: 100%; height: 100%;
        z-index: 78787; left: 0; top: 0; margin: 0;
    }
}
#boxPop {
    min-width: 1034px;
    max-width: 1034px;
    min-height: 560px;
    margin: 100px auto 50px auto;
    position: relative;
    z-index: 878787;

    .close_pop {
        position: absolute;
        width: 30px;
        height: 30px;
        background: url(../images/material/pop_close.png) no-repeat 0 0;
        right: 0;
        top: -60px;
        cursor: pointer;
    }
    .content_pop {
        background: none; min-height: 500px; position: relative; text-align: center;
        img {height: auto; max-width: 100%; width: 100%;}
        .detTxt {
            color: #fff; text-align: left; padding: 40px 0;
            .detCate {font-size: 16px; padding: 0 0 20px 0; font-weight: 800;}
            .detTitle {font-size: 40px; font-weight: 300;}
            .detDes {font-size: 16px; padding: 20px 0 0 0; line-height: 24px; font-weight: 300;}
        }
    }


    .popArrow {
        position: absolute; top: 50%; width: 90px; height: 60px; background: rgba(0,0,0, 0.5); cursor: pointer; text-indent: -999999px;
        z-index: 3;
        @include transform(translateY(-50%));
        &:after {
            position:absolute; width: 42px; height: 42px; content: ""; top: 50%;
            @include transform(translateY(-50%));
        }
        &.prev {
            left: 0; @include borderRadius(0 30px 30px 0);
            &:after {
                right:10px;
                background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;
            }
        }
        &.next {
            right: 0; @include borderRadius(30px 0 0 30px);
            &:after {
                left:10px;
                background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;
            }
        }
        &:hover {
            &.prev {
                &:after {background:url('../images/material/btn-arrow-prev-yellow.png') no-repeat 0 0;}
                &.slick-disabled {
                    &:after {background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;}
                }
            }
            &.next {
                &:after {background:url('../images/material/btn-arrow-next-yellow.png') no-repeat 0 0;}
                &.slick-disabled {
                    &:after {background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;}
                }
            }
        }
    }
}
.sc_content {
    z-index: 22;
    min-height: 500px;
    .inner {
        padding: 0 0 0 0;
    }
    .detImg {
        position: relative;
        img {position: relative; z-index: 2;}
        .vid {
            height:550px;
            iframe {height: 100%; width: 100%;}
        }
    }
}

.wrap-tankyou {
    background: $c_f5f; text-align: center; font-size: 16px; min-height: 638px;
    h1 {margin: 0 0 25px 0;}
    p {margin-bottom: 30px;}
    .container {width: 700px; padding: 150px 0 0px 0;}
    &.w404 {
        h1 {

            font-size: 100px; color: $c_green;
        }
        p {
            line-height: 30px;
        }
    }
}


.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    display: none;
    text-indent: -9999px;
    background: $c_004 url('../images/material/arr-top.png') no-repeat center center;
    cursor: pointer; z-index: 8888;
    @include borderRadius(50%);
    @include boxShadow(0 1px 2px $c_333);
}

body.lock {overflow-y: hidden;}
.wrap_popup {
    background: url(../images/material/bg_overlay.png) repeat 0 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    top: 0;
    left: 0;
    overflow-y: auto;
    .pop_inner {
        display: table;
        height: 100%;
        width: 100%;
        position: absolute;
        .middle_pop {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .overlay_bg {
        position: absolute; width: 100%; height: 100%;
        z-index: 78787; left: 0; top: 0; margin: 0;
    }
}
#boxPop-2 {
    min-width: 940px;
    max-width: 940px;
    min-height: 540px;
    margin: 0 auto;
    position: relative;
    z-index: 878787;
    @include borderRadius(0px);
    @include boxShadow(0 0 0px #999);

    .close_pop {
        position: absolute;
        width: 40px;
        height: 40px;
        background: url(../images/material/pop_close.png) no-repeat center center;
        right: -40px;
        top: 0px;
        cursor: pointer;
        z-index: 4;
    }
    .content_pop {
        padding: 0 0 0 0; background: none; min-height:400px; position: relative; z-index: 2;
        text-align: center;
        @include boxSizing(border-box);
        img {margin: 0 0 30px 0;}
        h3 {font-weight: 300; margin: 0 0 10px 0;}
        p {
            font-weight: 300; font-size:16px; line-height: 24px;
            &.small {font-size: 12px; line-height: 15px; color: $c_999;}
            &.img {
                margin-bottom: 10px;
                img {margin-bottom: 0;}
            }
        }
        .form {
            text-align: left;
        }
        .video-pop {
            height: 540px;
            iframe {height: 100%; width: 100%;}
        }
        .caption {
            font-size: 33px; font-weight: 300; line-height: 42px; color: $c_fff; text-align: left; padding:15px 0 0 0;
        }
    }
}

.tabscroll {
    position: absolute; top: -129px; left: 0; background: #ccc; width: 100%; height: 0;
}

.fxo-widget-iframe {
    right: 70px !important;
    bottom: 30px !important;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.603);
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .box-popup {
        padding: 15px;
        width: 80%;
        max-width: 790px;
        background: #FFF;
        position: relative;
        z-index: 2;
        figure {
            > img {
                width: 100%;
            }
        }
        .content-popup {
            margin: 17px 0;
            max-height: 75vh;
            overflow: auto;
            padding: 0 30px;
            &.center {
                text-align: center;
            }
            h4 {
                font-size: 24px;
                margin-bottom: 15px;
                font-weight: bold;
                color: #245E59;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                margin-bottom: 20px;
            }
            ol {
              list-style: none;
              counter-reset: counter;
            
              li {
                counter-increment: counter;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                padding-bottom: 25px;
                position: relative;
                padding-left: 25px;
            
                &:before {
                  content: counter(counter) ". ";
                  color: #333333;
                  position: absolute;
                  left: 0;
                }
            
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
            ul {
              list-style: none;

              li {
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                padding-bottom: 25px;
                position: relative;
                padding-left: 25px;

                &:before {
                  content: "";
                  background: #000;
                  border-radius: 50%;
                  height: 10px;
                  color: #333333;
                  position: absolute;
                  left: 0;
                  top: 4px;
                  width: 10px;
                }

                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
        }
        button {
            background: #9C9C34;
            border-radius: 20px;
            padding: 13px 0;
            width: 180px;
            color: #333333;
            font-size: 13px;
            font-weight: 700;
            font-family: 'Montserrat', sans-serif;
            @include transition(all .2s ease-in-out);
            &:hover {
                background-color: #b4b447;
            }
        }
        .close-popup {
            position: absolute;
            right: 4px;
            top: -30px;
            cursor: pointer;
            @include transition(all .2s ease-in-out);
            &:hover {
                transform: rotate(90deg);
            }
        }
    }
    &.popup-success {
        .box-popup {
            max-width: 580px;
            padding: 65px 50px;
            border-radius: 20px;
            figure {
                text-align: center;
                > img {
                    width: auto;
                }
            }
            .close-popup {
                right: 15px;
                top: 15px;
            }
            .content-popup {
                padding: 0 0 10px;
                h3 {
                    font-size: 28px;
                    font-weight: 700;
                    color: #1D4A41;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 16px;
                    line-height: 1.4;
                    font-weight: 400;
                    color: #555555;
                }
            }
            button {
                width: 100%;
                background: #EABA2B;
                border-radius: 5px;
                text-align: center;
                padding: 15px 0;
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                @include transition(all .3s ease-in-out);
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    &.popup-terms {
        background: rgba(0, 0, 0, 0.78);
        .box-popup {
            max-width: 900;
            padding: 0;
            border-radius: 20px;
            .close-popup {
                right: 10px;
                top: -44px;
                img {
                    width: 32px;
                }
            }
            .title-popup {
                padding: 25px 60px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #EDEDED;
                figure {
                    img {
                        width: 32px;
                    }
                }
                h3 {
                    font-size: 24px;
                    font-weight: 500;
                    color: #1D4A41;
                    margin-left: 20px;
                }
            }
            .content-popup {
                margin-top: 0;
                padding: 25px 45px 25px 60px;
                .text-popup {
                    max-height: 60vh;
                    height: 450px;
                    padding-right: 30px;
                    overflow: auto;
                    ol {
                        list-style: none;
                        counter-reset: counter;
                        li {
                            counter-increment: counter;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1.3;
                            color: #4B4B4B;
                            padding-bottom: 25px;
                            position: relative;
                            padding-left: 25px;
                            &:before {
                                content: counter(counter) ". ";
                                color: #323232;
                                font-weight: 700;
                                position: absolute;
                                left: 0;
                            }
                            &:last-child {
                                padding-bottom: 0;
                            }
                        }
                    }
                    &::-webkit-scrollbar {
                        width: 6px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #EDEDED;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #0F6961;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #108076;
                    }
                }
            }
        }
    }
    &#popup-datatable{
      .box-popup{
        border-radius: 20px;
      }
        .close-popup{
            top: auto;
            bottom: 100%;
        }
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
footer {
    background: $c_e0f; padding: 34px 0 30px 0; border-bottom: 3px solid $c_004; color: $c_333;
    position: relative; overflow: hidden;
    .ftop {
        @include clearfix(); margin-bottom: 20px; font-size: 14px; border-bottom: 1px solid $c_ccc;
        padding-bottom: 15px;
        .cl {
            float: left; font-weight: normal; position: relative;
            a {color: $c_333;}
            &.mail {
                margin-right: 15px; border-right:1px solid $c_666; padding: 7px 10px 8px 40px;
                &:before {
                    position:absolute; width: 30px; height: 30px; content: ""; left: 0; top: 0;
                    background: url('../images/material/ico-email.png') no-repeat center -3px;
                }
            }
            &.telp {
                a {
                    color: $c_green_5; padding: 8px 10px 8px 40px; display: inline-block;
                    font-weight: bold;
                    &:before {
                        position:absolute; width: 30px; height: 30px; content: ""; left: 0; top: 0;
                        background: url('../images/material/ico-telp.png') no-repeat center -1px;
                    }
                }
            }
        }
    }
    .fbot {
        @include clearfix();
    }
    .copyright {
        font-size: 12px; line-height: 12px; font-weight: normal; margin: 10px 25px 0 0;
    }
    .social-foot {
        padding: 3px 0 0 0;
        a {
            display: inline-block; padding: 0 10px; height: 24px; text-indent: -999999px;
            &.sc-fb {width: 13px; background: url('../images/material/sc-fb.png') no-repeat center center;}
            &.sc-link {width: 25px; background: url('../images/material/sc-link.png') no-repeat center center;}
            &.sc-ig {width: 25px; background: url('../images/material/sc-ig.png') no-repeat center center;}
            &.sc-tube {width: 28px; background: url('../images/material/sc-tube.png') no-repeat center center;}
            &:hover {
                opacity: 0.8;
            }
        }
    }
}


.ui-widget.ui-widget-content {width: 20.5em !important;}
.ui-datepicker .ui-datepicker-title select {
    padding: 5px;
    -webkit-appearance: default;
    -moz-appearance:    default;
    appearance:         default;
    background:$c_fff url('../images/material/ico-arr-2.png') no-repeat 92% center;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {top: 6px;}

.login {
    .accent {
        position: absolute;
        opacity: 0.2;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        img {
            height: 100vh;
        }
    }
    .wrapper {
        width: auto;
        padding: 25px 30px;
        display: flex;
        .side-form {
            flex: 0 0 calc(55% - 30px);
            max-width: calc(55% - 30px);
            .content-form {
                width: 380px;
                margin: 50px auto 0;
                .heading {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    figure {
                        flex: 0 0 160px;
                        max-width: 160px;
                        > img {
                            margin-bottom: 10px;
                        }
                    }
                    h6 {
                        font-size: 16px;
                        line-height: 1.3;
                        padding-left: 45px;
                        position: relative;
                        &:before {
                            content: '';
                            width: 1px;
                            height: 100%;
                            background: #D9D9D9;
                            position: absolute;
                            top: 0;
                            left: 19px;
                        }
                    }
                }
                h2 {
                    font-weight: 500;
                    margin-bottom: 40px;
                }
                .attention {
                    margin-bottom: 40px;
                }
                form {
                    margin-bottom: 25px;
                    .form-field {
                        margin-bottom: 30px;
                        label {
                            font-size: 16px;
                            margin-bottom: 10px;
                            display: block;
                        }
                        input[type=text], input[type=password]{
                            width: 100%;
                            border: 1px solid #E6EEED;
                            border-radius: 8px;
                            padding: 15px;
                            font-size: 16px;
                        }
                    }
                }
                .form-button {
                    padding-top: 10px;
                    margin-bottom: 30px;
                    button {
                        width: 100%;
                        text-align: center;
                        padding: 15px 0;
                        background: #EABA2B;
                        color: #FFF;
                        font-size: 16px;
                        border: unset;
                        border-radius: 5px;
                        position: relative;
                        @include transition(all .16s ease-in-out);
                        i {
                            position: absolute;
                            width: 7px;
                            height: 12px;
                            background: url('../images/material/i-login.svg') no-repeat 0 0;
                            top: 18px;
                            margin-left: 10px;        
                            @include transition(all .16s ease-in-out);                    
                        }
                        &:hover {
                            opacity: 0.8;
                            i {
                                margin-left: 15px;
                            }
                        }
                    }
                }
                a {
                    font-size: 16px;
                    color: #1D4A41;
                    text-decoration: underline;
                    &:hover {
                        color: #EABA2B;
                    }
                }
                p {
                    font-size: 16px;
                    color: #6A6A6A;
                    margin-bottom: 20px;
                }
            }
        }
        .side-img {
            flex: 0 0 45%;
            max-width: 45%;
            margin-left: 30px;
            figure {
                > img {
                    object-fit: cover;
                    width: 100%;
                    height: calc(100vh - 50px);
                    border-radius: 20px;
                }
            }
        }
    }
}

.eca-top {
    padding-top: 110px;
    background: #1D4A41;
    border-bottom-right-radius: 55px;
    border-bottom: 6px solid #EABA2B;
    position: relative;
    &:before {
        content: '';
        background: url('../images/material/accent-eca-1.png') no-repeat 0 0;
        background-size: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }
    &:after {
        content: '';
        background: url('../images/material/accent-eca-2.png') no-repeat;
        background-size: contain;
        background-position: right;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.2;
    }
    .wrapper {
        width: 1280px;
        position: relative;
        z-index: 2;
        .container {
            position: relative;
            padding: 80px 0 220px;
            color: #ffffffbf;
            font-weight: 400;
            .heading-txt {
                width: 535px;
                margin-bottom: 40px;
                h2 {
                    color: #FFF;
                    font-size: 32px;
                    font-weight: 500;
                    margin-bottom: 20px;
                    padding-left: 20px;
                    position: relative;
                    &:before {
                        content: '';
                        background: #EABA2B;
                        width: 6px;
                        height: 29px;
                        position: absolute;
                        top: -2px;
                        left: 0;
                        border-bottom-right-radius: 6px;
                    }
                }
                p {
                    font-size: 19px;
                    line-height: 1.3;
                }
            }
            form {
                label {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 16px;
                }
                input[type=text]{
                    width:100%;
                    border-radius: 5px;
                    font-size: 16px;
                }
                select {
                    width: 100%;
                    border-radius: 5px;
                    font-size: 16px;
                    background: #FFF url('../images/material/arrow-select.svg') no-repeat right;
                    background-position-x: 95%;
                }
                button {
                    width: 100%;
                    height: 43px;
                    border-radius: 5px;
                    background: #EABA2B;
                    img {
                        margin-top: 3px;
                    }
                }
            }
            .content-centered {
                display: flex;
                padding: 0 0 50px;
                margin: 0 165px;
                justify-content: space-between;
                .content-img {
                    flex: 0 0 200px;
                    max-width: 200px;
                }
                .content-text {
                    flex: 0 0 calc(100% - 230px);
                    max-width: 0 0 calc(100% - 230px);
                    h3 {
                        color: #88D3C0;
                        margin-bottom: 15px;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 1.2;
                    }
                    p {
                        font-size: 16px;
                        line-height: 1.3;
                        font-weight: 400;
                        color: #e9e9e9;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        flex-direction: row;
        margin-left: -10px;
        width: calc(100% + 20px);
        .column {
            padding: 0 10px;
            display: block;
            margin-left: 0;
            width: 100%;
            box-sizing: border-box;
            &.column-30 {
                flex: 0 0 30%;
                max-width: 30%;
            }
            &.column-10 {
                flex: 0 0 10%;
                max-width: 10%;
            }
            &.column-20{
                flex: 0 0 20%;
                max-width: 20%;
            }
        }
    }
}
.eca-content {
    .wrapper {
        width: 1280px;
        .wrap-rounded {
            background: #FFF;
            box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.05);
            border-radius: 40px;
            margin-top: -180px;
            position: relative;
            z-index: 2;
            .content {
                padding: 50px 40px;
                .content-centered {
                    display: flex;
                    padding: 30px 0;
                    margin: 0;
                    justify-content: space-between;
                    .content-img {
                        flex: 0 0 200px;
                        max-width: 200px;
                    }
                    .content-text {
                        flex: 0 0 calc(100% - 230px);
                        max-width: 0 0 calc(100% - 230px);
                        h3 {
                            color: #1D4A41;
                            margin-bottom: 10px;
                            font-size: 28px;
                            font-weight: 500;
                            line-height: 1.2;
                        }
                        p, ul li, ol li {
                            font-size: 19px;
                            line-height: 1.3;
                            font-weight: 400;
                            color: #555555;
                        }
                        ul{
                          padding-left: 20px;
                          margin-bottom: 20px;
                          li{
                            list-style-type: disc;
                          }
                        }
                        ol{
                          padding-left: 20px;
                          margin-bottom: 20px;
                          li{
                            list-style-type: decimal;
                          }
                        }
                    }
                }
                .title-bar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;
                    min-height: 48px;
                    h3 {
                        font-size: 23px;
                        font-weight: 700;
                        color: #1D4A41;
                    }
                    .search {
                        display: flex;
                        border: 1px solid #E6EEED;
                        border-radius: 8px;
                        padding: 3px 5px;
                        input {
                            width: 300px;
                            border: none;
                            font-size: 16px;
                        }
                        button {
                            width: 15px;
                            background: unset;
                            margin-right: 10px;
                        }
                    }
                }
                .regist-date {
                    display: flex;
                    color: #444444;
                    margin-bottom: 25px;
                    span {
                        font-size: 16px;
                        font-weight: 400;
                    }
                    h6 {
                        font-size: 16px;
                        font-weight: 600;
                        margin-left: 15px;
                    }
                }
                .list-eca {
                    margin-bottom: 30px;
                    .data-popup{
                        display: none;
                    }
                    table {
                        width: 100%;
                        th,td {
                            vertical-align: middle;
                            padding: 25px 20px;
                            font-size: 16px;
                            text-align: left;
                            color: #444444;
                            &:first-child {
                                padding-right: 0;
                            }
                        }
                        thead {
                            border-bottom: 1px solid #D9D9D9;
                            th {
                                font-weight: 600;
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    font-weight: 400;
                                    color: #5A5A5A;
                                    padding: 35px 20px;
                                    margin: 20px 0;
                                    &:nth-child(2) {
                                        font-weight: 500;
                                    }
                                    &:nth-child(3) {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                    &.registered {
                        table {
                            td {
                                &:first-child {
                                    color: #0F6961;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
                .list-eca-foot {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        font-size: 16px;
                        font-weight: 400;
                        color: #6A6A6A;
                        a {
                            font-size: 16px;
                            font-weight: 500;
                            color: #0F6961;
                            text-decoration: underline;
                        }
                    }
                    .btn-yellow {
                        width: 440px;
                    }
                }
            }
        }
    }
}
.head-eca {
    height: 110px;
    .container {
        padding: 16px 0;
        .heading {
            width: 380px;
            display: flex;
            align-items: center;
            margin: 5px auto 0;
            @include transition(all .3s ease-in-out);
            figure {
                flex: 0 0 160px;
                max-width: 160px;
                @include transition(all .3s ease-in-out);
                > img {
                    margin-bottom: 10px;
                }
            }
            h6 {
                font-size: 16px;
                line-height: 1.3;
                padding-left: 45px;
                position: relative;
                @include transition(all .3s ease-in-out);
                &:before {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background: #D9D9D9;
                    position: absolute;
                    top: 0;
                    left: 19px;
                }
            }
        }
    }
    &.float {
        .container {
            padding: 10px 0 5px;
            .heading {
                width: 341px;
                figure {
                    flex: 0 0 130px;
                    max-width: 130px;
                }
                h6 {
                    font-size: 12px;
                }
            }
        }
    }
}
.foot-eca {
    background: #FFF;
    border: unset;
    .fbot {
        text-align: center;
        p {
            margin: 0;
            color: #B5B5B5;
        }
    }
}
.foot-parent {
    padding: 34px 0 30px 0;
    background: #FFF;
    border: unset;
    .fbot {
        text-align: center;
        p {
            margin: 0;
            color: #B5B5B5;
        }
    }
}

.mid {
    .side-left {
        background: #1D4A41;
        border-radius: 0px 60px 0px 0px;
        position: fixed;
        left: 0;
        top: 0;
        width: 300px;
        height: 100vh;
        overflow: auto;
        direction:rtl; 
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: url('../images/material/accent-nav-top.png') no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.2;
            z-index: -1;
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: url('../images/material/accent-nav-bottom.png') no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0.2;
            z-index: -1;
        }
        .content {
            width: auto;
            height: auto;
            padding: 60px 35px;
            color: #FFF;
            position: relative;
            direction: ltr; 
            h4 {
                font-size: 19px;
                font-weight: 500;
              }
            .top{
              margin-bottom: 40px;
              display: flex;
              align-items: center;
            }
            .wrap-child {
                padding-bottom: 20px;
                border-bottom: 1px solid #ffffff24;
                .list-child {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 13px 0;
                    @include transition(all .2s ease-in-out);
                    .child-txt {
                        flex:0 0 calc(100% - 56px);
                        max-width: calc(100% - 56px);
                        padding-right: 5px;
                        h6 {
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFF;
                            margin-bottom: 8px;
                        }
                        span {
                            font-size: 14px;
                            color: #FFF;
                            opacity: 0.8;
                        }
                    }
                    .child-pic {
                        display: none;
                        flex: 0 0 56px;
                        max-width: 56px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 50px;
                        input[type="file"]{
                            width: 100%;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            border: none;
                            opacity: 0;
                            cursor: pointer;
                            z-index: 3;
                        }
                        img {
                            width: 56px;
                            height: 56px;
                            object-fit: cover;
                        }
                        .hover{
                            position: absolute;
                            background-color: rgba(0, 0, 0, 0.6);
                            z-index: 1;
                            width: 100%;
                            height: 0;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;   
                            color: #fff;
                            font-size: 30px;
                            line-height: 56px;
                            text-align: center;
                            opacity: 0;     
                            transition: .2s all ease-out;
                            cursor: pointer;
                            display: block;
                        }
                        &:hover{
                            .hover{
                                opacity: 1;
                                height: 100%;
                            }
                        }
                    }
                    &:hover {
                        background: rgba(255, 255, 255, 0.1);
                        padding-left: 15px;
                    }
                    &.active {
                        background: rgba(255, 255, 255, 0.1);
                        padding: 10px 15px;
                        border-radius: 4px;
                        border-left: 4px solid #EABA2B;
                        margin-bottom: 10px;
                        .child-pic {
                            display: block;
                        }
                    }
                }
            }
            nav {
                ul {
                    li {
                        padding: 8px 15px;
                        font-size: 15px;
                        color: #FFF;
                        @include transition(all .2s ease-in-out);
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    &.main-menu {
                        margin: 35px 0 25px;
                        padding-bottom: 120px;
                        border-bottom: 1px solid #ffffff24;
                        li {
                            position: relative;
                            padding: 15px 15px 15px 55px;
                            border-radius: 4px;
                            svg {
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                left: 15px;
                                top: 10px;
                            }
                            &.active {
                                background: #EABA2B;
                                font-weight: 700;
                                margin: 6px 0 8px;
                                color: #113028;
                                svg {
                                    path {
                                        fill: #113028;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #EDEDED;
        }
        &::-webkit-scrollbar-thumb {
            background: #0F6961;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #108076;
        }
    }
    .side-right {
        padding-left: 300px;
        .wrapper {
            width: auto;
            margin: 0 35px 50px;
        }
        
        .top-bar {
            padding: 20px 0;
            margin-bottom: 15px;
            .flex-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .burger-menu-eca{
                  display: none;
                }
                .side-logo {
                    width: 380px;
                    display: flex;
                    align-items: center;
                    figure {
                        flex: 0 0 160px;
                        max-width: 160px;
                        > img {
                            margin-bottom: 10px;
                        }
                    }
                    h6 {
                        font-size: 16px;
                        line-height: 1.3;
                        padding-left: 45px;
                        position: relative;
                        &:before {
                            content: '';
                            width: 1px;
                            height: 100%;
                            background: #D9D9D9;
                            position: absolute;
                            top: 0;
                            left: 19px;
                        }
                    }
                }
                .side-btn {
                    width: 155px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    figure {
                        position: relative;
                        img {
                            cursor: pointer;
                        }
                        .dot {
                            width: 8px;
                            height: 8px;
                            border-radius: 10px;
                            background: #E5262A;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                    button {
                        background: unset;
                        border: 2px solid #1D4A41;
                        border-radius: 6px;
                        padding: 7px 17px;
                        color: #1D4A41;
                        font-size: 16px;
                        font-weight: 700;
                        position: relative;
                        @include transition(all .2s ease-in-out);
                        &:before {
                            content: '';
                            width: 1px;
                            height: 28px;
                            background: #D9D9D9;
                            position: absolute;
                            top: 3px;
                            left: -25px;
                        }
                        &:hover {
                            background: #1D4A41;
                            color: #FFF;
                        }
                    }
                    .box-notif {
                        display: none;
                        position: absolute;
                        top: 50px;
                        right: 110px;
                        width: 307px;
                        padding: 35px 30px;
                        background: #ECF4F4;
                        border-radius: 4px;
                        z-index: 5;
												max-height: 475px;
												overflow: auto; 
												scrollbar-width: thin;  
												scrollbar-color: #ABACAC #EEEEEF;
												&::-webkit-scrollbar {
													width: 6px;
												}
												&::-webkit-scrollbar-track {
													background: #EEEEEF;
												}
												&::-webkit-scrollbar-thumb {
													background: #ABACAC;
												}
												&::-webkit-scrollbar-thumb:hover {
													background: #8A8B8B;
												}
                        .close-notif {
                            display: none;
                        }
                        .list-notif {
                            padding-bottom: 35px;
														display: block;
                            &:last-child {
                                padding: 0;
                            }
                            h5 {
                                font-size: 14px;
                                line-height: 1.4;
                                font-weight: 700;
                                color: #113028;
                                margin-bottom: 5px;
                            }
                            h6 {
                                font-size: 12px;
                                font-weight: 600;
                                color: #333333;
                                margin-bottom: 7px;
                            }
                            p {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 1.4;
                                color: #777777;
                                margin: 0;
                            }
														&.selected {
															opacity: .6; 
														}
                        }
                    }
                    &.active {
                        .box-notif {
                            display: block;
                        }
                    }
                }
            }
        }
        .title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            min-height: 48px;
            h3 {
                font-size: 23px;
                font-weight: 700;
                color: #1D4A41;
            }
            .search {
                display: flex;
                border: 1px solid #E6EEED;
                border-radius: 8px;
                padding: 3px 5px;
                input {
                    width: 300px;
                    border: none;
                    font-size: 16px;
                }
                button {
                    width: 15px;
                    background: unset;
                    margin-right: 10px;
                }
            }
        }
        .tab-item {
            margin-bottom: 60px;
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin-right: 40px;
                    position: relative;
                    &:before {
                        content: '';
                        background: #EABA2B;
                        width: 0;
                        height: 3px;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                    }
                    a {
                        font-size: 16px;
                        font-weight: 400;
                        color: #555555;
                        &:hover {
                            font-weight: 600;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &.active {
                        a {
                            color: #1D4A41;
                            font-weight: 700;
                        }
                        &:before {
                            width: 85%;
                        }
                    }
                }
            }
        }
        .filter-row {
            display: flex;
            align-items: center;
            margin-bottom: 47px;
            span {
                font-size: 15px;
                color: #333333;
                font-weight: 500;
            }
            select {
                margin-left: 10px;
                font-size: 16px;
                padding: 14px 15px;
                width: 267px;
                border: 1px solid #E6EEED;
                border-radius: 8px;
                position: relative;
                background: url('../images/material/arrow-select.svg') no-repeat right;
                background-position-x: 230px;
            }
        }
        .school-item {
            .wrap-items {
                border: 1px solid #EFEFEF;
                border-radius: 8px;
                margin-bottom: 40px;
                position: relative;
                figure {
                    display: flex;
                    width: auto;
                    height: 275px;
                    text-align: center;
                    img {
                        margin: auto;
                    }
                }
                figcaption {
                    padding: 30px 25px;
                    h5 {
                        color: #111111;
                        font-size: 19px;
                        font-weight: 700;
                    }
                    h6 {
                        color: #777777;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.2;
                        margin-bottom: 10px;
                    }
                }
                &.action{
                    .overlay-cart{
                        opacity: 1;
                    }
                }
            }
            &.canteen {
                .wrap-items {
                    position: relative;
                    figure {
                        display: block;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            margin: 0;
                            border-radius: 8px 8px 0 0;
                        }
                    }
                }
            }
            .overlay-cart {
                opacity: 0;
                background: #ECF4F4;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 8px;
                padding: 30px 27px;
                @include transition(all .2s ease-in-out);
                .close{
                    font-size: 22px;
                    position: absolute;
                    top:10px;
                    right: 10px;
                    cursor:pointer;
                    z-index: 2;
                }
                h5 {
                    color: #111111;
                    font-size: 19px;
                    font-weight: 700;
                    margin-bottom: 30px;
                }
                h6 {
                    color: #777777;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                .form-control {
                    margin-bottom: 20px;
                    span {
                        color: #777777;
                        font-size: 16px;
                    }
                    .radio-size {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 8px;
                        overflow: hidden;
                        overflow-y: auto;
                        height: 70px;
                        scrollbar-width: thin;
                        scrollbar-color: #EABA2B;
                        &::-webkit-scrollbar {
                          width: 6px;
                        }
                        &::-webkit-scrollbar-track {
                            background: #EDEDED;
                            border-radius: 5px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: #EABA2B;
                            border-radius: 5px;
                        }
                        &::-webkit-scrollbar-thumb:hover {
                            background: #EABA2B;
                        }
                        label {
                            width: auto;
                            position: relative;
                            color: #777777;
                            background-color: #FFF;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            height: 40px;
                            line-height: 38px;
                            display: block;
                            cursor: pointer;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            border-radius: 4px;
                            margin-right: 10px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            padding: 0 10px;
                            white-space: nowrap;
                        }
                        input {
                            display: none;
                            &:checked + label {
                                background-color: #1D4A41;
                                color: #FFF;
                            }
                        }
                    }
                    .input-quantity {
                        display: flex;
                        align-items: center;
                        margin-top: 8px;
                        .minus, .plus {
                            width: 40px;
                            height: 40px;
                            border: none;
                            background: #FFF;
                            font-size: 20px;
                            border-radius: 3px 0 0 3px;
                            text-align: center;
                            line-height: 40px;
                            cursor: pointer;
                        }
                        .plus {
                            border-radius: 0 3px 3px 0;
                        }
                        input {
                            font-size: 14px;
                            border: unset;
                            height: 40px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .wrap-day {
            .day {
                margin-bottom: 30px;
                h3 {
                    font-size: 23px;
                    font-weight: 500;
                }
            }
            .category-title {
                margin-bottom: 30px;
                h4 {
                    font-size: 19px;
                    font-weight: 500;
                    color: #484848;
                }
            }
        }
        .invoice {
            .flex-grid {
                display: flex;
                align-items: flex-start;
                .grid-l {
                    flex: 0 0 70%;
                    max-width: 70%;
                    padding-left: 25px;
                }
                .grid-r {
                    flex: 0 0 30%;
                    max-width: 30%;
                    padding-right: 25px;
                }
                .grid-in {
                    display: flex;
                    h6 {
                        width: 110px;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #5A5A5A;
                    }
                }
                h5 {
                    font-size: 16px;
                    color: #5A5A5A;
                    font-weight: 600;
                }
                h6 {
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 5px;
                }
            }
            .recipient {
                padding-bottom: 25px;
                border-bottom: 1px solid #E6EEED;
            }
            .payment-detail {
                margin-bottom: 30px;
                h6 {
                    font-weight: 600;
                    color: #596865;
                }
                .thead {
                    padding: 25px 0;
                }
                .tbody {
                    background: #E6EEED;
                    border-radius: 8px;
                    padding: 30px 0;
                    .flex-grid {
                        padding-bottom: 30px;
                        &:last-child {
                            padding: 0;
                        }
                        .grid-l {
                            font-size: 16px;
                            color: #555555;
                            font-weight: 400;
                        }
                        .grid-r {
                            font-size: 16px;
                            font-weight: 700;
                            color: #333333;
                        }
                    }
                }
            }
            .notes {
                .btn-yellow{
                  &.only-mobile{
                    display: none;
                  }
                }
                p {
                    font-size: 12px;
                    font-weight: 400;
                    color: #777777;
                }
                .flex-grid {
                    .grid-l {
                        padding-left: 0;
                        padding-right: 40px;
                    }
                    .grid-r {
                        padding: 0;
                    }
                }
                .total {
                    margin-bottom: 40px;
                    h5 {
                        font-weight: 700;
                    }
                    h6 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .cart {
            padding-right: 335px;
            .cart-category {
                margin-bottom: 60px;
                .cart-title {
                    display: flex;
                    border-bottom: 1px solid #E6E6E6;
                    padding-bottom: 25px;
                    .category-name {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
            .input-quantity {
                display: flex;
                align-items: center;
                justify-content: center;
                .minus, .plus {
                    width: 40px;
                    height: 40px;
                    border: none;
                    background: #FFF;
                    font-size: 20px;
                    border-radius: 3px 0 0 3px;
                    text-align: center;
                    line-height: 40px;
                    cursor: pointer;
                }
                .plus {
                    border-radius: 0 3px 3px 0;
                }
                input {
                    font-size: 14px;
                    border: unset;
                    height: 40px;
                    text-align: center;
                    padding: 0;
                }
            }
            table {
                margin-top: 20px;
                width: 100%;
                th,td {
                    vertical-align: middle;
                    font-size: 16px;
                    text-align: left;
                    padding: 15px 10px;
                }
                th {
                    font-weight: 600;
                    color: #666666;
                    &:nth-child(2) {
                        padding-left: 0;
                    }
                    &:nth-child(4) {
                        text-align: center;
                    }
                }
                td {
                    &:first-child {
                        padding: 0;
                        .custom-checkbox {
                            top: -2px;
                        }
                    }
                    &:nth-child(2) {
                        padding-left: 0;
                        width: 120px;
                    }
                    &:nth-child(3){
                        width: 320px;
                        h5 {
                            font-size: 16px;
                            font-weight: 600;
                            color: #113028;
                        }
                        h6 {
                            font-size: 14px;
                            line-height: 1.2;
                            font-weight: 500;
                            color: #777777;
                            margin-bottom: 5px;
                        }
                        span {
                            font-size: 14px;
                            font-weight: 300;
                            color: #666666;
                            display: block;
                            margin-bottom: 5px;
                        }
                    }
                    &:nth-child(4){
                        width: 140px;
                    }
                    &:nth-child(5){
                        width: 155px;
                    }
                    &:last-child {
                        padding-right: 0;
                        button {
                            background: unset;
                        }
                    }
                }
            }
        }
        .summary {
            width: 300px;
            background: #ECF4F4;
            border-radius: 4px;   
            position: fixed;  
            right: 35px;
            top: 150px;
            padding: 35px 25px;
            h3 {
                font-size: 19px;
                font-weight: 700;
                color: #333333;
                margin-bottom: 30px;
            }
            h6 {
                font-size: 14px;
                font-weight: 600;
                color: #596865;
                margin-bottom: 10px;
            }
        }
        .payment {
            .payment-detail {
                .box-payment {
                    background: #e5eeee;
                    padding: 25px 35px 50px;
                    margin-bottom: 20px;
                    border-bottom: 15px solid #f9f9f9;
                    position: relative;
                    .payment-name {
                        font-size: 19px;
                        font-weight: 700;
                        color: #1D4A41;
                        margin-bottom: 15px;
                    }
                    .payment-number {
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 25px;
                    }
                    .payment-total {
                        font-size: 19px;
                        font-weight: 700;
                        color: #1D4A41;
                    }
                    select {
                        width: 120px;
                        padding: 8px 0;
                        font-size: 14px;
                        color: #000;
                        font-weight: 700;
                        background: none;
                        border: none;
                        border-bottom: 1px solid #ccc;
                        position: absolute;
                        top: 8px;
                        right: 20px;
                        appearance: auto;
                    }
                }
                h6 {
                    text-align: center;
                    font-size: 16px;
                    line-height: 1.5;
                    margin-bottom: 20px;
                }
            }
            .accordion-payment {
                font-size: 16px;
                border-bottom: 1px solid #ccc;
                .acclist {
                    .acc-title {
                        font-weight: 700;
                        color: #333333;
                        border-top: 1px solid #ccc;
                        border-bottom: none;
                        padding-left: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 66px;
                        .logo-payment{
                          img{
                            height: 40px;
                            margin-left: 12px;
                          }
                        }
                        &:after{
                          top: 50%;
                          transform: translateY(-50%);
                        }
                    }
                    .acc-descrip {
                        padding: 15px 30px 25px;
                        .form-control {
                            margin-bottom: 25px;
                            label {
                                display: block;
                                margin-bottom: 7px;
                            }
                            input, select {
                                width: 100%;
                                background: #FFFFFF;
                                border: 1px solid #E6EEED;
                                border-radius: 8px;
                                height: 50px;
                                font-size: 16px;
                                color: #333333;
                            }
                            select{
                              position: relative;
                              background: url('../images/material/arr-acc.png') no-repeat center right 20px;
                              padding-right: 40px;
                            }
                            input[type=email]{
                                font-size: 16px;
                            }
                            .card-number {
                                width: 100%;
                                font-size: 16px;
                                font-weight: 700;
                                @include andplaceholder{font-weight: 400;}
                            }
                        }
                        .installment {
                            margin-bottom: 40px;
                            .installment-title {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 10px;
                                h6 {
                                    font-size: 16px;
                                }
                                a {
                                    font-size: 16px;
                                    text-decoration: underline;
                                    color: #245E59;
                                }
                            }
                        }
                        .wrap-button {
                            button {
                                font-size: 14px;
                                padding: 12px 45px;
                                border-radius: 3px;
                                margin-right: 15px;
                                &:hover {
                                    opacity: 0.8;
                                }
                                &.btn-cancel {
                                    background: #FFF;
                                    border: 1px solid #dfdddd;
                                    color: #5A5A5A;
                                }
                                &.btn-submit {
                                    background: #EABA2B;
                                    border: 1px solid #EABA2B;
                                    color: #FFF;
                                }
                            }
                        }
                        .box-select{
                          cursor: pointer;
                          position: relative;
                          min-height: 50px;
                          background: #FFFFFF;
                          border: 1px solid #E6EEED;
                          border-radius: 8px;
                          .selected, .item-list .item {
                            cursor: pointer;
                            display: flex;
                            height: 100%;
                            padding: 10px 0;
                            width: 100%;
                            &:hover {
                              background: #f5f5f5;
                            }
                          }
                          .selected {
                            position: relative;
                            padding-right: 54px;
                            &::after {
                              position:absolute; 
                              width: 24px; 
                              height: 24px; 
                              content: ""; 
                              top: 50%;
                              transform: translateY(-50%); 
                              right: 16px;
                              background: url('../images/material/arr-acc.png') no-repeat center;
                              @include transition(all .2s ease-in-out);
                            }
                          }
                          .item-list {
                            background: #fff;
                            border:2px solid #E5E5E5;
                            display: none;
                            left: -2px;
                            position: absolute;
                            right: 2px;
                            top: 100%;
                            width: calc(100% + 4px);
                            z-index: 99;
                          }
                          figure{
                            align-items: center;
                            display: flex;
                            width: 150px;
                            img {
                              display: block;
                              height: 30px;
                              margin: 0 auto;
                              object-fit: contain;
                              width: 100%;
                            }
                          }
                          label {
                            align-items: center;
                            display: flex;
                            cursor: pointer;
                            font-size: 16px;
                            line-height: 1.8;
                            margin-bottom: 0;
                            padding-left: 16px;
                            position: relative;
                            width: calc(100% - 150px);
                            small {
                              display: block;
                            }
                            
                          }
                          .input-select{
                            height: 100%;
                            position: relative;
                            small{
                              position: absolute;
                              top: 8px;
                              left: 150px;
                            }
                            select{
                              border:none;
                              height: 100%;
                              width: 100%;
                              padding: 0px;
                              padding-left: 100px;
                              line-height: 1;
                              background: url('../images/material/icon-caret-down.svg')no-repeat 97% center;
                              cursor: pointer;
                            }
                          }
                      
                          &.long{
                            .input-select{
                              select{
                                padding-left: 150px;
                                padding-top: 5px;
                              }
                            }
                          }
                        }
                        &.wallet-payment{
                          .box-input-payment{
                            &.shopee, &.qris{
                              display: none;
                            }
                            &.shopee{
                              align-items: center;
                              .btn-submit{
                                width: 100%;
                              }
                            }
                          }
                        }
                    }
                }
            }
            .copyright {
                padding: 35px 15px;
                p {
                    font-size: 14px;
                    color: #333333;
                    font-weight: 600;
                }
            }
        }
        .misc {
            .accordion-misc {
                font-size: 16px;
                .acclist {
                    margin-bottom: 20px;
                    .acc-title {
                        font-size: 16px;
                        font-weight: 500;
                        color: #555555;
                        border-top: unset;
                        border-bottom: none;
                        padding-left: 15px;
                        background: #ECF4F4;
                        border-radius: 4px;
                    }
                    .acc-descrip {
                        padding: 25px 30px 5px;
                    }
                }
            }
            p {
                font-size: 14px;
                line-height: 1.5;
                font-weight: 400;
                color: #777777;
            }
        }
        .otp {
            background: #EFEFEF;
            display: flex;
            padding: 90px 0 0;
            figure {
                margin: auto;
            }
        }
    }
    .nav-mobile-eca{
      background: #1D4A41;
      border-radius: 0px 60px 0px 0px;
      position: fixed;
      left: 0;
      top: 0;
      width: 95px;
      height: 100vh;
      overflow: auto;
      direction:rtl; 
      padding: 50px 20px;
      opacity: 0;
      z-index: 9;
      display: none;
      transition: 0.2s all ease-out;
      &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: url('../images/material/accent-nav-top.png') no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.2;
          z-index: -1;
      }
      &:after {
          content: '';
          width: 100%;
          height: 100%;
          background: url('../images/material/accent-nav-bottom.png') no-repeat;
          background-size: cover;
          position: absolute;
          bottom: 0;
          right: 0;
          opacity: 0.2;
          z-index: -1;
      }
      nav{
        margin-top: 45px;
        padding: 40px 0;
        position: relative;
        &::after{
          content: '';
          width: 100%;
          height: 1px;
          background: #D9D9D9;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.1;
        }
        &::before{
          content: '';
          width: 100%;
          height: 1px;
          background: #D9D9D9;
          position: absolute;
          bottom: 0;
          right: 0;
          opacity: 0.1;
        }
        ul{
          li{
            padding: 10px 0;
            text-align: center;
            border-radius: 4px;
            margin-bottom: 10px;
            &.active{
              background: #EABA2B;
              svg{
                path{
                  fill: #113028;
                }
              }
            }
          }
        }
      }
    }
    .close-menu-eca{
      display: none;  position: relative; right: 0; cursor: pointer;
      width: 34px; height: 29px; z-index: 858585; margin: 0 auto; top: 10px;
      span {
        position: absolute; width: 34px; height: 2.7px; background: #EABA2B; display: block; text-indent: -999999px;
        right: 0; width: 24.83px;
        @include transform(rotate(0deg));
        @include transition( .25s ease-in-out);
        @include borderRadius(0px);
        &:nth-child(1) {top: 0px; @include transform(rotate(140deg));}
        &:nth-child(2) {top: 0px; opacity: 0;}
        &:nth-child(3) {top: 15px; @include transform(rotate(-140deg));}
      }
    }
    .burger-menu-eca {
      display: block;  position: relative; right: 0; cursor: pointer;
      width: 34px; height: 29px; z-index: 858585; margin: 0 auto;
      span {
          position: absolute; width: 34px; height: 2.7px; background: #EABA2B; display: block; text-indent: -999999px;
          right: 0;
          @include borderRadius(3px);
          @include transform(rotate(0deg));
          @include transition( .25s ease-in-out);

          &:nth-child(1) {
              top: 0;
              @include transformorigin(left center);
          }
          &:nth-child(2) {
              top: 12px;
              @include transformorigin(left center);
          }
          &:nth-child(3) {
              top: 24px;
              @include transformorigin(left center);
          }
      }

      &.expand {
        span {
          position: absolute; display: block;
          right: 0;width: 24.83px;
          @include borderRadius(0px);
          &:nth-child(1) {top: 0px; @include transform(rotate(140deg));}
          &:nth-child(2) {top: 0px; opacity: 0;}
          &:nth-child(3) {top: 31px; @include transform(rotate(-140deg));}
        }
      }
    }
    .pagging {
        position: relative;
        padding: 32px 0;
        margin-top: 30px;
        text-align: center;
        ul {
            margin: 0;
            display: inline-block;
            text-align: center;
            padding: 0 50px;
            position: relative;
            li {
                display: inline-block;
                margin: 0 12px;
                position: relative;
                a {
                    color: #333333;
                    font-size: 16px;
                    &.active {
                        color: #EABA2B;
                        font-weight: 700;
                    }
                }
                &.prev {
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: auto;
                    margin: 0;
                }
                &.next {
                    position: absolute;
                    transform: translateY(-50%);
                    right: -20px;
                    left: auto;
                    top: 50%;
                    margin: 0;
                }
            }
        }
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        flex-direction: row;
        margin-left: -15px;
        width: calc(100% + 30px);
        .column {
            padding: 0 15px;
            display: block;
            margin-left: 0;
            flex: 1 1 auto;
            width: 100%;
            &.column-33 {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
            &.column-50 {
                flex: 0 0 50%;
                max-width: 50%;
            }
            &.column-25 {
                flex: 0 0 25%;
                max-width: 25%;
            }
            &.column-35 {
                flex: 0 0 35%;
                max-width: 35%;
            }
            &.column-65 {
              flex: 0 0 65%;
              max-width: 65%;
            }
            &.column-75 {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
    }
}
.btn-yellow {
    width: 100%;
    background: #EABA2B;
    border-radius: 5px;
    text-align: center;
    padding: 15px 0;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition(all .3s ease-in-out);
    &:hover {
        opacity: 0.8;
    }
    img {
        position: relative;
        top: 0;
        margin-left: 12px;
    }
}
.std-content{
  ul {
    font-weight: 300; padding: 0 0 30px 0; margin-left:10px;margin: 0 0 0 0;
    li {
        position: relative; padding: 3px 0 3px 25px; margin:0 0 15px 0; line-height: 20px; font-size: 14px !important;
        &:before {
            position: absolute; width: 10px; height: 10px; content: ""; left: 0; top: 12px;
            background: url('../images/material/bullet-1.png') no-repeat 0 0;
        }
        ul {
            padding: 5px 0 10px 0; margin: 0 0 0 25px;
            li {
                padding: 0 0 0 24px;
                &:before {
                    background: url('../images/material/bullet-2.png') no-repeat 0 0;
                }
            }
        }
    }
    &.space {margin-left: 45px;}
  }
  ol {
      margin: 0 0 0 0; padding: 0 0 30px 0; font-weight: 300;
      counter-reset: my-badass-counter;
      li {
          font-size: 14px; padding: 3px 0 3px 40px; position: relative;
          margin:0 0 15px 0; line-height: 14px;
          &:before {
              position: absolute; left: 2px; top: 4px;
              content: counter(my-badass-counter);
              counter-increment: my-badass-counter;
          }
          &:after {
              position: absolute; content: ""; left: 26px; top: 0; width: 1px; height: 100%;
              background: $c_333; opacity: 0.5;
          }
      }
      &.styled-2 {
          @include clearfix();
          width: 50%; padding: 8px 0 0 40px;
          li {float: left; width: 40%;}
      }
  }
  .table-std {padding: 25px 0 80px 0;}
  table {
      width: 100%; border:1px solid $c_d1d; font-weight: 300; text-align: center;
      thead {
          tr {
              th {
                  vertical-align: middle; padding: 17px 10px; font-size: 16px; font-weight: 500;
                  background: $c_green_8; border:1px solid $c_d1d;
                  &.yellow {background: $c_yellow_4;}
              }
              &.med {
                  th {font-size: 14px;}
              }
          }
      }
      tbody {
          tr {
              td {vertical-align: middle; padding: 17px 10px; font-size: 14px; border:1px solid $c_d1d;}
          }
      }
      tfoot {
          tr {
              td {
                  vertical-align: middle; padding: 17px 10px; font-size: 14px; border:1px solid $c_d1d;
                  background: $c_yellow_3;
              }
          }
      }
      &.styled-2 {
          border:0; text-align: left;
          thead {
              tr {
                  th {
                      padding: 17px 15px;
                      background: $c_yellow_3; border:0; font-size: 16px; color: $c_green;
                      line-height: 24px;
                      &:nth-child(1) {padding-left: 25px;}
                  }
              }
          }
          tbody {
              tr {
                  td {
                      padding: 18px 15px; border:0;
                      &:nth-child(1) {padding-left: 25px;}
                  }
              }
          }
      }
      &.width-2 {
          thead {
              th {
                  &:nth-child(1){width: 13%;}
                  &:nth-child(2){width: 11%;}
                  &:nth-child(3){width: 15%;}
                  &:nth-child(4){width: 20%;}
                  &:nth-child(5){width: 10%;}
                  &:nth-child(6){width: 10%;}
                  &:nth-child(7){width: 10%;}
                  &:nth-child(8){width: 10%;}
              }
          }
          tbody {
              tr {
                  &:nth-child(even) {td{background:$c_green_9;}}
              }
          }
      }
  }
}
.wrap-btn-pdf {
    .btn-pdf {
        position: relative;
        display: inline-block;
        width: 308px;
        background: #FFF;
        border: 1px solid #E9E9E9;
        border-radius: 8px;
        padding: 11px 15px 11px 55px;
        text-align: left;
        cursor: pointer;
        @include transition(all .2s ease-in-out);
        &:hover {
            background: #f3f3f3;
        }
        &:before {
            content: '';
            width: 25px;
            height: 30px;
            background: url('../images/material/ico-pdf.svg') no-repeat;
            position: absolute;
            top: 15px;
            left: 15px;
        }
        h6 {
            font-size: 16px;
            font-weight: 600;
            color: #0F6961;
            margin-bottom: 7px;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: #5A5A5A;
        }
    }
}
.check {
    width: 32px;
    height: 18px;
    position: relative;
    .custom-checkbox { 
        position: relative;
        input {
            width: 18px; 
            height: 18px; 
            position: absolute; 
            left: 0; 
            top: 0; 
            z-index: 2;
            opacity: 0; 
            cursor: pointer;
            margin-bottom: 0;
            padding: 0;
            &:checked {
                + i {
                    border: 1px solid #EABA2B;
                    background: url('../images/material/checkbox.svg') no-repeat center;
                }
            }
            &:disabled {
              cursor: default; 
                + i {
                    background: #d7dade;
                    border-radius: 3px;
                    border:1px solid #abafb6;  
                } 
            }
        }
        i {
            border: 1px solid #A7A7A7;
            border-radius: 4px;
            top: 0px;
            display: block;
            width: 18px; 
            height: 18px; 
            position: absolute; 
            left: 0;
        }
    }
}

.select2-container .select2-selection--single {
  height: 41px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  border-radius: 5px;
  font-size: 16px;
  line-height: 41px;
  padding-left: 10px;
  padding-right: 35px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 41px;
  width: 35px;
  b {
    border: none;
    background: url("../images/material/arrow-select.svg") no-repeat center;
    height: 20px;
    margin: 0;
    transform: translate(-50%,-50%);
    width: 20px;
  }
}
.select2-container--default .select2-results__option[aria-selected=true] {
  font-size: 16px;
  line-height: normal;
  padding: 10px;
}